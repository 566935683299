import React, { useState, useRef, useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { Link } from 'react-router-dom';
import './Chatbot.css';
import { IoCloseSharp } from "react-icons/io5";
import { BsChatDotsFill } from "react-icons/bs";


const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([
    { text: "Hello! How can I help you today? Ask me anything about our printing services.", from: "bot" },
  ]);
  const [userInput, setUserInput] = useState('');
  const messagesEndRef = useRef(null);

  // FAQ questions and answers with direct product links
  const faqs = [
    {
      question: "I want to print business cards",
      answer: "Sure! We offer a variety of business card options. Check out our Business Cards section here:",
      link: "/adminproducts",
    },
    {
      question: "Tell me about T-shirt printing",
      answer: "We provide custom T-shirt printing. You can choose designs, colors, and sizes. Visit our T-shirt category here:",
      link: "/adminproducts",
    },
    {
      question: "Can I print custom mugs?",
      answer: "Yes! We offer custom mug printing. Find our selection here:",
      link: "/adminproducts",
    },
    {
      question: "Do you offer flyer printing?",
      answer: "Yes, we offer flyer printing services. Learn more here:",
      link: "/adminproducts",
    },
    {
      question: "I need branded bags for my business",
      answer: "We provide branded bags as part of our product range. Check them out here:",
      link: "/adminproducts",
    },
    {
      question: "What is the minimum quantity of prints?",
      answer: "The minimum order quantity is 50 units for most products, but it may vary depending on the type of product."
    },
    {
      question: "What type of lamination do you offer?",
      answer: "We offer both matte and gloss lamination options to fit your preference."
    },
    {
      question: "What is the difference between matte and gloss lamination?",
      answer: "Matte lamination gives a soft, non-reflective finish, while gloss lamination is shiny and reflective."
    },
    { question: "When will I receive my order?", answer: "Your order will typically be delivered within 3-5 business days, depending on your location." },
    { question: "What is the minimum quantity of prints I can order?", answer: "The minimum order quantity is usually 50 units, but this may vary depending on the type of product." },
    { question: "How do I place an order on your website?", answer: "To place an order, browse our products, select the desired options, add them to your cart, and complete checkout." },
  ];

  const contactNumber = "08029299901";

  const chatWindowAnimation = useSpring({
    transform: isOpen ? 'translateY(0%)' : 'translateY(100%)',
    opacity: isOpen ? 1 : 0,
  });

  const toggleChat = () => setIsOpen(!isOpen);

  const sendMessage = (e) => {
    e.preventDefault();
    if (userInput.trim()) {
      const userMessage = { text: userInput, from: 'user' };
      setMessages([...messages, userMessage]);

      const matchingFaq = faqs.find(faq =>
        faq.question.toLowerCase().includes(userInput.toLowerCase())
      );

      setTimeout(() => {
        if (matchingFaq) {
          setMessages(prevMessages => [
            ...prevMessages,
            { text: matchingFaq.answer, from: "bot" },
            matchingFaq.link ? { text: <Link to={matchingFaq.link}>Click here to view the product</Link>, from: "bot" } : null
          ].filter(Boolean));
        } else {
          setMessages(prevMessages => [
            ...prevMessages,
            { text: `I'm sorry, I couldn't understand that. Please call us at ${contactNumber} or chat with us on WhatsApp.`, from: "bot" },
            { text: `Call us at: `, link: `tel:${contactNumber}`, from: "bot" },
            { text: `WhatsApp us at: `, link: `https://wa.me/${contactNumber}`, from: "bot" },
          ]);
        }
      }, 1000);

      setUserInput('');
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <div className="chatbot-container">
      <button className="chat-togglee-btn" onClick={toggleChat}>
        {isOpen ? <IoCloseSharp />: <BsChatDotsFill />}
      </button>

      {/* Chat Window */}
      <animated.div style={chatWindowAnimation} className="chat-window">
        <div className="chat-header">
          <h3>Chat with Us</h3>
        </div>
        <div className="chat-messages">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`chat-message ${msg.from === 'user' ? 'user-message' : 'bot-message'}`}
            >
              {msg.text}
              {msg.link && (
                <a href={msg.link} target="_blank" rel="noopener noreferrer">
                  {msg.text.includes("Click here") ? "View Product" : msg.text.includes("Call") ? "Call us" : "WhatsApp us"}
                </a>
              )}
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
        <form onSubmit={sendMessage} className="chat-input">
          <input
            type="text"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            placeholder="Type a message..."
          />
          <button type="submit">Send</button>
        </form>
      </animated.div>
    </div>
  );
};

export default Chatbot;
