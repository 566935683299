// src/pages/ResetPassword.jsx
import React, { useState } from 'react';
import { auth } from '../../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import './Auth.css'; // Import shared CSS for consistency

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess('Password reset email sent. Check your inbox.');
    } catch (error) {
      setError('Failed to send reset email. Please check your email and try again.');
    }
  };

  return (
    <div className="auth-container">
      <form className="auth-form" onSubmit={handleResetPassword}>
        <h2>Reset Password</h2>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">{success}</p>}
        <label>Email:</label>
        <input 
          type="email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          required 
        />
        <button type="submit">Send Reset Email</button>
        <a href="/login" className='back-to-login'>Back to login</a>
      </form>
    </div>
  );
};

export default ResetPassword;
