// src/components/ImagePopup.jsx
import React, { useEffect, useState } from 'react';
import './ImagePopup.css';
import { FaLongArrowAltRight, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const ImagePopup = ({ images, videoUrl, onClose }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const imageInterval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Transition images every 3 seconds

    return () => clearInterval(imageInterval); // Clear interval on component unmount
  }, [images.length]);

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button onClick={onClose} className="close-button"><FaTimes /></button>
        <img src={images[currentImageIndex]} alt="Popup" className="popup-image" />
        <div className="popup-text">
          <h1>Welcome to Multibrand Digital Services</h1>
          <h3>Your Home of Printing</h3>
          <p>
            Discover quality printing solutions tailored to your needs. From vibrant promotional materials to personalized stationery, we're here to bring your ideas to life with precision and excellence.
            Explore our range of services and let's create something remarkable together!
          </p>
          <Link to="/adminproducts" className="explore-link">
            <span>Explore Now </span> <FaLongArrowAltRight />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ImagePopup;
