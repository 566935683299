import React, { useState, useEffect } from 'react';
import { GrNext, GrPrevious } from 'react-icons/gr';
import './Hero2.css';



const Hero2 = () => {

  const images = [
    { 
      url: 'https://media.istockphoto.com/id/2152774804/vector/black-background-3d-abstract-architecture-wallpaper-geometry-cover-wavy-texture-ripple.jpg?s=612x612&w=0&k=20&c=LAx8Zkb0B1Ti34hpnm3YFe0E8sH_v5JR62zoFf27blw=', 
      title: 'Welcome to Multibrand Digital Services', 
      description1: 'Transforming ideas into high-quality print solutions with precision and care.', 
    },
    
    { 
      url: 'https://media.istockphoto.com/id/1629468915/vector/high-contrast-dark-grey-stripes-with-shadows-and-lights-on-gradient-background-abstract.jpg?s=612x612&w=0&k=20&c=TtpgSKV6swS-xgvpRZudUbVhhCAkvNMM__bRVs4wZck=', 
      title: 'Cutting-Edge Printing Technology', 
      description1: 'Experience the future of printing with state-of-the-art technology and techniques.', 
    },
    
    { 
      url: '/images/h14.png', 
      title: 'Sustainable Printing Solutions', 
      description1: 'Leading the way in eco-friendly printing for a greener tomorrow.', 
    },
    
    { 
      url: 'https://media.istockphoto.com/id/2027666837/photo/abstract-background-with-acrylic-paint.jpg?s=612x612&w=0&k=20&c=VomSU9aeGS8apmo1VmcEP0K8QEiypXEJol_kQSS7HNA=', 
      title: 'Industrial-Scale Print Excellence', 
      description1: 'Supporting businesses of all sizes with tailored solutions that meet industry standards.', 
    },
    
    { 
      url: 'https://media.istockphoto.com/id/1498657258/vector/abstract-dark-blue-blurred-defocused-gradient-background-with-dynamic-effect.jpg?s=612x612&w=0&k=20&c=vMPhXTUdwfOK04ZM7k7qRPahqb3EogVJSWI5NmFpo9U=', 
      title: 'Creative Print Services', 
      description1: 'Combining artistry and technology for prints that leave a lasting impact.', 
    },
    
    { 
      url: '/images/h6.png', 
      title: 'Comprehensive Branding Materials', 
      description1: 'From business cards to banners, elevate your brand with our full range of products.', 
    },
    
    { 
      url: '/images/h7.png', 
      title: 'Dedicated Client Support', 
      description1: 'Our experienced team is here to guide you through every step of the printing process.', 
    },
    
    { 
      url: '/images/h8.png', 
      title: 'Global Printing Solutions', 
      description1: 'Expanding your brand’s reach with high-quality prints across the globe.', 
       
    }
  ];
  

  const [activeIndex, setActiveIndex] = useState(0);
  const lastIndex = images.length - 1;

  useEffect(() => {
    const handleResize = () => {
      const slider = document.querySelector('.slider');
      const widthSlider = slider.offsetWidth;
      const heightSlider = slider.offsetHeight;
      const diameter = Math.sqrt(Math.pow(widthSlider, 2) + Math.pow(heightSlider, 2));
      document.documentElement.style.setProperty('--diameter', diameter + 'px');
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    const setSlider = () => {
    

      document.getElementById('next').classList.remove('d-none');
      document.getElementById('prev').classList.remove('d-none');
      if (activeIndex === lastIndex) document.getElementById('next').classList.add('d-none');
      if (activeIndex === 0) document.getElementById('prev').classList.add('d-none');
    };
    setSlider();
  }, [activeIndex, lastIndex]);


  const nextSlide = () => {
    setActiveIndex((prev) => (prev === lastIndex ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setActiveIndex((prev) => (prev === 0 ? lastIndex : prev - 1));

    
  };

  return (
    <div className='image-slider'>
      <section className="slider">
        <div className="list">
          {images.map((image, index) => (
            <div key={index} className={`item ${index === activeIndex ? 'active' : ''}`}>
              <div className="image" style={{ '--url': `url(${image.url})` }}></div>
              <div className="content">
                <h2>{image.title}</h2>
                <p>{image.description1}</p>
               
                
              </div>
            </div>
          ))}
        </div>
        <div className="arrows">
          <button onClick={prevSlide} disabled={activeIndex === 0} id='prev'><GrPrevious /></button>
          <button onClick={nextSlide} disabled={activeIndex === lastIndex} id='next'><GrNext/></button>
        </div>
      </section>
     
    </div>
  );
};

export default Hero2;