import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa'; // Import social media icons
import './CompanyProfile.css';
import GoogleMap from './GoogleMap';

const CompanyProfile = () => {
  return (
    <div className="company-profile">
      <div className="company-header">
        <h1>Multibrand Digital Services</h1>
        <p>Your trusted partner for tech innovation and top-quality printing services.</p>
      </div>

      <div className="company-details">
        <div className="company-description">
          <h2>About Us</h2>
          <p>
            Multibrand Digital Services is at the forefront of software development and branding solutions. We provide high-quality, innovative digital solutions, as well as custom printing services to help businesses build strong brands.
          </p>
          <h2>Our Mission</h2>
          <p>
            To empower businesses through creative technology and branding services, helping them stand out in their industry with innovative products and exceptional customer service.
          </p>
        </div>

        <div className="company-services">
          <h2>What We Offer</h2>
          <ul>
            <li>Custom Software Development</li><br />
            <li>Web and Mobile App Solutions</li><br />
            <li>Custom Printing (Books, T-shirts, Mugs, Bags, etc).</li>
            <li>Branding & Corporate Identity</li><br />
            <li>Business Stationery & Marketing Collateral</li>
          </ul>
        </div>

        <div className="company-values">
          <h2>Our Values</h2>
          <ul>
            <li>INNOVATION: Constantly evolving and embracing new ideas.</li>
            <li>CUSTOMER FIRST: Committed to delivering quality service to meet client needs.</li>
            <li>INTEGRITY: Transparency and trust are at the heart of everything we do.</li>
            <li>EXCELLENCE: Striving for perfection in every project.</li>
          </ul>
        </div>

        <div className="company-contact">
          <h2>Contact Information</h2>
          <p>Email: info@multibrand.com</p>
          <p>Phone: +234 802 929 9901</p>
          <p>Address: 123 Main Street, Lagos, Nigeria</p>
          <Link className="quick-order" to={"https://wa.me/2348029299901"}>Quick Order via WhatsApp</Link>

          <div className="social-media">
            <h2>Follow Us</h2>
            <div className="social-icons">
              <a href="https://www.facebook.com/multibrandprint" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
              <a href="https://www.instagram.commultibrandprints" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
              <Link to={"/about"}>MBDS</Link>
            </div>
          </div>
        </div>
      </div>
      <GoogleMap/>

      <div className="company-products">
        <h2>Our Catalogs</h2>
        <ul>
          <li><Link to="/category/Books">Books</Link></li>
          <li><Link to="/category/Tshirt_Branding">T-shirt Branding</Link></li>
          <li><Link to="/category/Custom_Mugs">Custom Mugs</Link></li>
          <li><Link to="/category/Bags">Bags</Link></li>
          <li><Link to="/category/Business_Cards">Business Cards</Link></li>
        </ul>
      </div>
    </div>
  );
};

export default CompanyProfile;
