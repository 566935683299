import React, { useState } from 'react';
import AdminProductList from '../components/admin/AdminProductList';
import Hero from '../components/Hero';
import Hero2 from '../components/Hero2';
import ImageSlider from '../components/ImageSlider';
import images from '../components/products/Image';
import ImagePopup from '../components/products/ImagePopup';
import Newsletter from '../components/NewsLetter';
import ScrollAnimation from '../components/ScrollAnimation';
import Advert from '../components/Advert';
import './Home.css';
import { Helmet } from 'react-helmet';
import GoogleReviews from '../components/ReviewSlider';
import Counter from '../components/Counter';

const Home = () => {
  const [showPopup, setShowPopup] = useState(true);

  const imagesList = [
    '/images/book2.png',
    '/images/cloth1.png',
    '/images/bag5.png',
    '/images/box3.png',
  ];

  const videoUrl = 'https://www.youtube.com/embed/4yrpJn0kNNM';

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className='home'>
   <Helmet>
    <title>Multibrand Printing Services & Premium Printing Solutions, Lagos, Nigeria</title>
   <meta 
     name="description" 
    content="Multibrand Printing Services, Lagos Nigeria | offers high-quality digital printing, branding, and graphic design solutions in Lagos and across Nigeria. From business cards to large format prints, we bring your brand to life with exceptional service and innovative design." 
   />
  <meta 
    name="keywords" 
    content="Multibrand Printing Services, Lagos, Nigeria, best printers in Lagos, printers in Lagos, digital printing, printers in Nigeria, best printing press in shomolu, best printers in shomolu, branding, graphic design, business cards, large format printing, banners, custom printing, corporate branding, flyers, promotional products, printing services, print marketing, Multibrand Prints, Nigeria printing, top 10 printers in Lagos, best printers, multibrand, multibrand digital services, Multibrand Digital | Printing Services, Lagos, Nigeria" 
  />
  <meta 
    property="og:title" 
    content="Multibrand Printing Services, Lagos, Nigeria - Best Printers in Lagos, Nigeria & Premium Printing Solutions" 
  />
  <meta 
    property="og:description" 
    content="Explore top-quality digital printing and branding services, including business cards, custom banners, and promotional products. Trusted by businesses across Lagos and Nigeria for impactful and professional prints." 
  />
  <meta 
    property="og:image" 
    content="/images/bus4.png" 
  />
  <meta 
    property="og:url" 
    content="https://www.multibrandprints.com.ng/" 
  />
  <meta 
    name="twitter:card" 
    content="summary_large_image" 
  />
  <meta 
    name="twitter:title" 
    content="Multibrand Printing Services, Lagos, Nigeria - Best Printers in Lagos, Nigeria & Premium Printing Solutions" 
  />
  <meta 
    name="twitter:description" 
    content="Professional printing and branding services tailored to meet your business needs in Lagos and Nigeria. From personalized stationery to large promotional banners, we have you covered." 
  />
  <meta 
    name="twitter:image" 
    content="/images/bus4.png" 
  />
  <link rel="canonical" href="https://www.multibrandprints.com.ng/" />
   </Helmet>

 

      <div className="home-page">
        {showPopup && <ImagePopup images={imagesList} videoUrl={videoUrl} onClose={handleClosePopup} />}
      </div>
      <Hero2 />
      <Hero />
      <ImageSlider images={images} />
      
      <div className='newsletter-container'>
        <ScrollAnimation />
       
      </div>
      
      <div className="pppppp">
        {/* Button to toggle ProductsList */}
        <h1>Over 4,000 print ready templates</h1>
        <p>Select from a wide range of creative design templates. Simply edit and you’re done.</p> 
      </div>
        
      <AdminProductList />
      <Advert />
      <Newsletter />
      <Counter/>
      <GoogleReviews />
    </div>
  );
};

export default Home;
