import React, { useState, useContext } from 'react';
import { CartContext } from '../../CartContext';
import { db } from '../../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import emailjs from 'emailjs-com';
import { PaystackButton } from 'react-paystack';
import './Checkout.css';

const Checkout = () => {
  const { cartItems, clearCart } = useContext(CartContext);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
  });
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [deliveryOption, setDeliveryOption] = useState('normal'); // State for delivery option
  const deliveryFees = {
    normal: 3000,
    express: 15000,
    pickup: 0,
  };

  const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
  const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  const deliveryFee = deliveryFees[deliveryOption]; // Get the selected delivery fee
  const amount = (totalPrice + deliveryFee) * 100; // Total amount including delivery fee

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDeliveryChange = (e) => {
    setDeliveryOption(e.target.value); // Update delivery option
  };

  const submitOrder = async () => {
    setLoading(true);
    setErrorMessage(''); // Reset error message
    setSuccessMessage(''); // Reset success message

    try {
      const orderData = {
        customerDetails: formData,
        items: cartItems.map(item => ({
          id: item.id,
          name: item.name,
          quantity: item.quantity,
          price: item.price,
          customInfo: item.customInfo, // Assuming customInfo exists on the item
        })),
        timestamp: serverTimestamp(),
        deliveryOption: deliveryOption,
        deliveryFee: deliveryFee,
      };

      // Submit order to Firestore
      const orderRef = await addDoc(collection(db, 'orders'), orderData);
      console.log('Order submitted with ID:', orderRef.id);

      // Send confirmation email
      const emailData = {
        to_name: formData.name,
        to_email: formData.email,
        message: `Thank you for your order! We have received your order and will process it shortly.`,
      };

      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        emailData,
        process.env.REACT_APP_EMAILJS_USER_ID
      );
      console.log('Email sent successfully to', formData.email);

      setSuccessMessage('Order submitted successfully! Payment was successful.');
      clearCart(); // Clear the cart after order submission
    } catch (error) {
      console.error('Error submitting order:', error);
      setErrorMessage('Failed to submit order. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const onPaymentSuccess = async () => {
    console.log('Payment successful. Submitting order...');
    try {
      await submitOrder();
    } catch (error) {
      console.error('Error in onPaymentSuccess:', error);
      setErrorMessage('Failed to submit order after payment. Please contact support.');
    }
  };

  const componentProps = {
    email: formData.email,
    amount,
    publicKey,
    text: "Pay Now",
    onSuccess: onPaymentSuccess,
    onClose: () => {
      console.log("Payment window closed without completing.");
      alert("Payment was not completed. Please try again.");
    },
  };

  return (
    <div className="checkout">
      <h2>Checkout</h2>
      <div className="checkout-box">

        {/* Cart Summary */}
        <div className="cart-summary">
          <h3>Your Items</h3>
          {cartItems.length === 0 ? (
            <p>Your cart is empty.</p>
          ) : (
            <ul>
              {cartItems.map((item) => (
                <li key={item.id}>
                  <div className="cart-item">
                    <img src={item.imageUrl} alt={item.name} className="cart-item-image" />
                    <div className="cart-item-details">
                      <h4>{item.name}</h4>
                      <p>Quantity: {item.quantity}</p>
                      <p>Price: ₦{(item.price * item.quantity).toLocaleString()}</p>
                      {item.customInfo && <p>Custom Info: {item.customInfo}</p>} {/* Display custom info if available */}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Customer Information Form */}
        <div className="checkout-form">
          <div>
            <label>Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              placeholder='Your name here...'
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              placeholder='Your email here...'
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label>Phone:</label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              placeholder='Your phone number here...'
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label>Address:</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              placeholder='Your delivery address here...'
              onChange={handleInputChange}
              required
            />
          </div>
        </div>

        {/* Delivery Options as Radio Buttons */}
        <div className="delivery-options">
          <label>Delivery Method:</label>
          <div>
            <input
              type="radio"
              id="normal"
              name="delivery"
              value="normal"
              checked={deliveryOption === 'normal'}
              onChange={handleDeliveryChange}
            />
            <label htmlFor="normal">Normal Delivery (₦3000)</label>
          </div>
          <div>
            <input
              type="radio"
              id="express"
              name="delivery"
              value="express"
              checked={deliveryOption === 'express'}
              onChange={handleDeliveryChange}
            />
            <label htmlFor="express">Express Delivery (₦15000)</label>
          </div>
          <div>
            <input
              type="radio"
              id="pickup"
              name="delivery"
              value="pickup"
              checked={deliveryOption === 'pickup'}
              onChange={handleDeliveryChange}
            />
            <label htmlFor="pickup">Pickup (Free)</label>
          </div>
        </div>

        {/* Total Price */}
        <div className="total-price">
          <h3>Total Price: ₦{(totalPrice + deliveryFee).toLocaleString()}</h3>
        </div>

        {/* Paystack Payment Button */}
        <PaystackButton className="paystack-button" {...componentProps} />

        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {loading && <p className="loading-message">Submitting your order...</p>}
      </div>
    </div>
  );
};

export default Checkout;
