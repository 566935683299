const ProductArray = {
    Books: [
        { name: 'Novel', id: 5551, image: "/images/book1.jpg", price: "2000", description: "A captivating novel filled with engaging stories and memorable characters.", quantity: "100", delivery: "5" },
        { name: 'Textbook', id: 56432, image: "/images/book2.png", price: "2500", description: "An essential textbook for students and professionals, covering all key topics.", quantity: "100", delivery: "5" },
        { name: 'Comics', id: 8756543, image: "/images/book3.png", price: "2000", description: "Enjoy the adventures of your favorite heroes in this thrilling comic book.", quantity: "100", delivery: "5" },
        
        { name: 'Novel', id: 6544343445, image: "/images/book5.png", price: "1800", description: "A gripping novel that will keep you hooked from start to finish.", quantity: "100", delivery: "5" },
        { name: 'Programme', id: 811111116, image: "/images/book6.png", price: "1200", description: "A detailed programme book for events and ceremonies.", quantity: "100", delivery: "5" },
        { name: 'Brochure', id: 7, image: "/images/book7.png", price: "5000", description: "An informative brochure showcasing various products and services.", quantity: "100", delivery: "5" },
        { name: 'Soft cover Book With Box', id: 776668, image: "/images/book8.png", price: "", description: "A softcover book packaged in an elegant box, perfect for gifts.", quantity: "100", delivery: "5" },
        { name: 'Hard Cover Notepad', id: 9889, image: "/images/book9.png", price: "", description: "A durable hard cover notepad for all your writing needs.", quantity: "100", delivery: "5" },
        { name: 'Hard Cover Book', id: 16540, image: "/images/book10.png", price: "", description: "A high-quality hard cover book for readers who prefer durability.", quantity: "100", delivery: "5" },
        { name: 'Soft cover Book', id: 1766451, image: "/images/book11.png", price: "", description: "A lightweight and portable soft cover book.", quantity: "100", delivery: "5" },
    ],
  
    Tshirt_Branding: [
        { name: 'T-shirt', id: 12, image: "/images/cloth1.png", price: "", description: "A comfortable and stylish t-shirt, perfect for everyday wear.", quantity: "100", delivery: "5" },
        { name: 'High Qyality T-shirt wear', id: 9892, image: "/images/cloth2.png", price: "", description: "High-quality T-shirt that combine comfort and durability.", quantity: "100", delivery: "5" },
        { name: 'Jacket', id: 13, image: "/images/cloth3.png", price: "", description: "A trendy jacket to keep you warm and fashionable.", quantity: "100", delivery: "5" },
        { name: 'Dress', id: 14, image: "/images/cloth4.png", price: "", description: "An elegant dress suitable for various occasions.", quantity: "100", delivery: "5" },
        { name: 'Dress', id: 15, image: "/images/cloth5.png", price: "", description: "A beautiful dress that combines style and comfort.", quantity: "100", delivery: "5" },
        { name: 'Dress', id: 16, image: "/images/cloth6.png", price: "", description: "A fashionable dress that makes a statement.", quantity: "100", delivery: "5" },
    ],
  
    Bags: [
        { name: 'Carrier Bag', id: 17, image: "/images/bag1.png", price: "", description: "A sturdy carrier bag for convenient transportation of goods.", quantity: "100", delivery: "5" },
        { name: 'Product Branding Nylon', id: 18, image: "/images/bag2.png", price: "", description: "A branded nylon bag ideal for product packaging.", quantity: "100", delivery: "5" },
        { name: 'Paper Bag', id: 23, image: "/images/bag3.png", price: "", description: "An eco-friendly paper bag perfect for shopping.", quantity: "100", delivery: "5" },
        { name: 'Product Bag', id: 19, image: "/images/bag4.png", price: "", description: "A versatile product bag for various uses.", quantity: "100", delivery: "5" },
        { name: 'Disposable Nylon Bag', id: 25, image: "/images/bag5.png", price: "", description: "A disposable nylon bag for easy and quick use.", quantity: "100" },
        { name: 'Quality Tote Bag', id: 26, image: "/images/bag6.png", price: "", description: "A high-quality tote bag, durable and suitable for all purposes.", quantity: "100" },
        { name: 'Bag', id: 27, image: "/images/bag7.png", price: "", description: "A general-purpose bag for everyday use.", quantity: "100" },
        { name: 'Kraft Mailer Bag', id: 28, image: "/images/bag8.png", price: "", description: "A sturdy kraft mailer bag for secure shipping.", quantity: "100" },
        { name: 'Disposable Nylon Bag', id: 29, image: "/images/bag9.png", price: "", description: "A handy disposable nylon bag for quick use.", quantity: "100" },
    ],
  
    Branding: [
        { name: 'Product Branding', id: 31, image: "/images/brand1.png", price: "10,000", description: "Professional product branding services to enhance your brand's image.", quantity: "100", delivery: "5" },
        { name: 'Kitchen Accessory Branding', id: 32, image: "/images/brand2.png", price: "", description: "Customized branding for kitchen accessories, perfect for promotions.", quantity: "100", delivery: "5" },
        { name: 'Exhibition Banner Branding', id: 33, image: "/images/brand3.png", price: "", description: "High-quality branding for exhibition banners to attract attention.", quantity: "100", delivery: "5" },
        { name: 'Round Badge Branding', id: 34, image: "/images/brand4.png", price: "", description: "Custom round badge branding for events and promotions.", quantity: "100", delivery: "5" },
        { name: 'Product Branding', id: 36, image: "/images/brand6.png", price: "", description: "Enhance your products with professional branding services.", quantity: "100", delivery: "5" },
        { name: 'Product Branding', id: 37, image: "/images/brand7.png", price: "", description: "Custom product branding to elevate your brand's presence.", quantity: "100", delivery: "5" },
    ],
  
    Letterhead: [
        { name: 'Quality Letterheaded Papers', id: 41, image: "/images/letterhead.png", price: "", description: "A classic lettehead for your company.", quantity: "300", delivery: "5" },
        { name: 'Quality Letterheaded Papers', id:42, image: "/images/letterhead2.png", price: "", description: "A classic lettehead for your company.", quantity: "300", delivery: "5" },
      
      
    ],
  
    Envelopes: [
        { name: 'Envelope', id: 51, image: "/images/envelope1.png", price: "", description: "A standard envelope for everyday mailing needs.", quantity: "100", delivery: "5" },
        { name: 'Envelope', id: 52, image: "/images/envelope5.png", price: "", description: "A high-quality envelope for professional correspondence.", quantity: "100", delivery: "5" },
        { name: 'Envelope', id: 53, image: "/images/envelope3.png", price: "", description: "A durable envelope to protect your important documents.", quantity: "100", delivery: "5" },
        { name: 'Envelope', id: 54, image: "/images/envelope4.png", price: "", description: "A stylish envelope for special occasions.", quantity: "100", delivery: "5" },
        { name: 'Envelope', id: 55, image: "/images/envelope5.png", price: "", description: "An elegant envelope, perfect for invitations.", quantity: "100", delivery: "5" },
        { name: 'Envelope', id: 56, image: "/images/envelope1.png", price: "", description: "A premium envelope for high-quality mailing.", quantity: "100", delivery: "5" },
    ],
  
    Custom_Mugs: [
        { name: 'Branded Mug', id: 61, image: "/images/cup1.png", price: "1,200", description: "A customized branded mug, ideal for promotions and gifts.", quantity: "", delivery: "3" },
        { name: 'Branded Mug', id: 62, image: "/images/cup2.png", price: "1,200", description: "A high-quality branded mug with a unique design.", quantity: "", delivery: "3" },
        { name: 'Branded Mug', id: 63, image: "/images/cup3.png", price: "1,200", description: "A stylish branded mug, perfect for coffee and tea.", quantity: "", delivery: "3" },
        { name: 'Branded Mug', id: 64, image: "/images/cup4.png", price: "1,200", description: "A durable branded mug for everyday use.", quantity: "", delivery: "3" },
      
    ],
  
    Business_Cards: [
        { name: 'Quality Business Cards', id: 651, image: "/images/card1.png", price: "8,500", description: "High-quality business cards to make a lasting impression.", quantity: "100", delivery: "2" },
        { name: 'Quality Business Cards', id: 662, image: "/images/card2.png", price: "8,500", description: "Professionally designed business cards for your business.", quantity: "100", delivery: "2" },
        { name: 'Quality Business Cards', id: 673, image: "/images/card3.png", price: "8,500", description: "Elegant business cards that stand out.", quantity: "100", delivery: "2" },
        { name: 'Quality Business Cards', id: 684, image: "/images/card4.png", price: "8,500", description: "Custom business cards with premium quality.", quantity: "100", delivery: "2" },
        { name: 'Quality Business Cards', id: 569, image: "/images/card5.png", price: "8,500", description: "Creative business cards to showcase your brand.", quantity: "100", delivery: "2" },
        { name: 'Quality Business Cards', id: 706, image: "/images/card6.png", price: "8,500", description: "Unique business cards to leave a lasting impression.", quantity: "100", delivery: "2" },
    ],
    Business_Stationeries:[
      { name: 'Quality Business Stationeries ', id: 71, image: "/images/bus1.png", price: "50,500", description: "Unique business stationeries to leave a lasting impression.", quantity: "50", delivery: "3" },
      { name: 'Quality Business Stationeries ', id: 72, image: "/images/bus2.png", price: "50,500", description: "Unique business stationeries to leave a lasting impression.", quantity: "50", delivery: "3" },
      { name: 'Quality Business Stationeries ', id: 73, image: "/images/bus3.png", price: "50,500", description: "Unique business stationeries to leave a lasting impression.", quantity: "50", delivery: "3" },
      { name: 'Quality Business Stationeries ', id: 74, image: "/images/bus4.png", price: "50,500", description: "Unique business stationeries to leave a lasting impression.", quantity: "50", delivery: "3" },
      { name: 'Quality Business Stationeries ', id: 75, image: "/images/bus5.png", price: "50,500", description: "Unique business stationeries to leave a lasting impression.", quantity: "50", delivery: "3" },
      { name: 'Quality Business Stationeries ', id: 76, image: "/images/bus6.png", price: "50,500", description: "Unique business stationeries to leave a lasting impression.", quantity: "50", delivery: "3" },
      { name: 'Quality Business Stationeries ', id: 77, image: "/images/bus7.png", price: "50,500", description: "Unique business stationeries to leave a lasting impression.", quantity: "50", delivery: "3" },
      { name: 'Quality Business Stationeries ', id: 78, image: "/images/bus8.png", price: "50,500", description: "Unique business stationeries to leave a lasting impression.", quantity: "50", delivery: "3" },
      { name: 'Quality Business Stationeries ', id: 79, image: "/images/bus9.png", price: "50,500", description: "Unique business stationeries to leave a lasting impression.", quantity: "50", delivery: "3" },
      { name: 'Quality Business Stationeries ', id: 810, image: "/images/bus10.png", price: "50,500", description: "Unique business stationeries to leave a lasting impression.", quantity: "50", delivery: "3" },
    ]
  };
  export default ProductArray