import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./UserList.css"

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  // Fetch users from Firestore
  const fetchUsers = async () => {
    try {
      const usersCollection = collection(db, 'users');
      const usersSnapshot = await getDocs(usersCollection);
      setUsers(usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    } catch (err) {
      setError('Failed to fetch users');
      console.error('Error fetching users:', err);
    }
  };

  // Delete user from Firestore
  const deleteUser = async (userId) => {
    try {
      await deleteDoc(doc(db, 'users', userId));
      toast.success('User deleted successfully!');
      fetchUsers(); // Refresh the users list
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error('Failed to delete user. Please try again.');
    }
  };

  useEffect(() => {
    fetchUsers(); // Fetch users on component mount
  }, []);

  // Helper function to create WhatsApp link
  const getWhatsAppLink = (phoneNumber) => {
    if (!phoneNumber) return '#'; // Handle missing phone numbers
    const formattedPhone = phoneNumber.replace(/[^\d]/g, ''); 
    return `https://wa.me/${formattedPhone}`;
  };

  // Helper function to create Call link
  const getCallLink = (phoneNumber) => {
    if (!phoneNumber) return '#'; // Handle missing phone numbers
    return `tel:${phoneNumber}`; // tel: scheme for initiating a phone call
  };

  if (error) {
    return <p className="error">{error}</p>;
  }

  return (
    <div className="user-list">
      <h3>Users List</h3>
      <ul>
        {users.map(user => (
          <li key={user.id}>
           <div className='user-info'>
            <p>Name: {user.name || 'N/A'}</p>
            <p>Email: {user.email || 'N/A'}</p>
            <p>Role: {user.role || 'N/A'}</p>
            <p>Address: {user.address ? `${user.address.streetAddress}, ${user.address.city}, ${user.address.state}, ${user.address.country}` 
    : 'N/A'}
</p>
            <p>Phone Number: {user.phoneNumber || 'N/A'}</p>
           </div>
           
            {/* WhatsApp link */}
            {user.phoneNumber && (
              < div className='user-contact'>
                <a href={getWhatsAppLink(user.phoneNumber)} target="_blank" rel="noopener noreferrer" >
                  WhatsApp
                </a>
                
                {/* call user */}
                <a href={getCallLink(user.phoneNumber)}>Call User</a>
                 {/* delete user Button */}
                <button onClick={() => deleteUser(user.id)}>Delete User</button>
              </div>
            )}
          </li>
        ))}
      </ul>
      <table border={2}>
        <thead>
          <tr>
            <th>User Name</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>Role</th>
            <th>Contact via WhatsApp</th>
            <th>Call User</th>
            <th>Delete User</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>{user.name || 'N/A'}</td>
              <td>{user.phoneNumber || 'N/A'}</td>
              <td>{user.email || 'N/A'}</td>
              <td>{user.role || 'N/A'}</td>
              
              {/* WhatsApp link in table */}
              <td>
                {user.phoneNumber && (
                  <a href={getWhatsAppLink(user.phoneNumber)} target="_blank" rel="noopener noreferrer">
                    WhatsApp
                  </a>
                )}
              </td>

              {/* Call User link in table */}
              <td style={{background:"blue"}}>
                {user.phoneNumber && (
                  <a href={getCallLink(user.phoneNumber)} >Call</a>
                )}
              </td>

              {/* delete user logic */}
              <td><button onClick={() => deleteUser(user.id)}>Delete User</button></td>

            </tr>
          ))}
        </tbody>
      </table>

      <ToastContainer />
    </div>
  );
};

export default UserList;










//with watsapp contact logic


// import React, { useState, useEffect } from 'react';
// import { db } from '../../firebase';
// import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import "./UserList.css"

// const UserList = () => {
//   const [users, setUsers] = useState([]);
//   const [error, setError] = useState(null);

//   // Fetch users from Firestore
//   const fetchUsers = async () => {
//     try {
//       const usersCollection = collection(db, 'users');
//       const usersSnapshot = await getDocs(usersCollection);
//       setUsers(usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
//     } catch (err) {
//       setError('Failed to fetch users');
//       console.error('Error fetching users:', err);
//     }
//   };

//   // Delete user from Firestore
//   const deleteUser = async (userId) => {
//     try {
//       await deleteDoc(doc(db, 'users', userId));
//       toast.success('User deleted successfully!');
//       fetchUsers(); // Refresh the users list
//     } catch (error) {
//       console.error('Error deleting user:', error);
//       toast.error('Failed to delete user. Please try again.');
//     }
//   };

//   useEffect(() => {
//     fetchUsers(); // Fetch users on component mount
//   }, []);

//   // Helper function to create WhatsApp link
//   const getWhatsAppLink = (phoneNumber) => {
//     if (!phoneNumber) return '#'; // Handle missing phone numbers
//     // Format the phone number (remove any spaces, dashes, or parentheses)
//     const formattedPhone = phoneNumber.replace(/[^\d]/g, ''); 
//     return `https://wa.me/${formattedPhone}`;
//   };

//   if (error) {
//     return <p className="error">{error}</p>;
//   }

//   return (
//     <div className="user-list">
//       <h3>Users List</h3>
//       <ul>
//         {users.map(user => (
//           <li key={user.id}>
//             <p>Name: {user.name || 'N/A'}</p>
//             <p>Email: {user.email || 'N/A'}</p>
//             <p>Role: {user.role || 'N/A'}</p>
//             <p>Phone Number: {user.phoneNumber || 'N/A'}</p>
//             <button onClick={() => deleteUser(user.id)}>Delete User</button>
//             {/* WhatsApp link */}
//             {user.phoneNumber && (
//               <a href={getWhatsAppLink(user.phoneNumber)} target="_blank" rel="noopener noreferrer">
//                 Contact User on WhatsApp
//               </a>
//             )}
//           </li>
//         ))}
//       </ul>
//       <table border={2}>
//         <thead>
//           <tr>
//             <th>User Name</th>
//             <th>Phone Number</th>
//             <th>Email</th>
//             <th>Role</th>
//             <th>Delete User</th>
//             <th>Contact via WhatsApp</th>
//           </tr>
//         </thead>
//         <tbody>
//           {users.map(user => (
//             <tr key={user.id}>
//               <td>{user.name || 'N/A'}</td>
//               <td>{user.phoneNumber || 'N/A'}</td>
//               <td>{user.email || 'N/A'}</td>
//               <td>{user.role || 'N/A'}</td>
//               <td><button onClick={() => deleteUser(user.id)}>Delete User</button></td>
//               {/* WhatsApp link in table */}
//               <td>
//                 {user.phoneNumber && (
//                   <a href={getWhatsAppLink(user.phoneNumber)} target="_blank" rel="noopener noreferrer">
//                     WhatsApp
//                   </a>
//                 )}
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       <ToastContainer />
//     </div>
//   );
// };

// export default UserList;






// without contact user logic


// import React, { useState, useEffect } from 'react';
// import { db } from '../../firebase';
// import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import "./UserList.css"


// const UserList = () => {
//   const [users, setUsers] = useState([]);
//   const [error, setError] = useState(null);

//   // Fetch users from Firestore
//   const fetchUsers = async () => {
//     try {
//       const usersCollection = collection(db, 'users');
//       const usersSnapshot = await getDocs(usersCollection);
//       setUsers(usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
//     } catch (err) {
//       setError('Failed to fetch users');
//       console.error('Error fetching users:', err);
//     }
//   };

//   // Delete user from Firestore
//   const deleteUser = async (userId) => {
//     try {
//       await deleteDoc(doc(db, 'users', userId));
//       toast.success('User deleted successfully!');
//       fetchUsers(); // Refresh the users list
//     } catch (error) {
//       console.error('Error deleting user:', error);
//       toast.error('Failed to delete user. Please try again.');
//     }
//   };

//   useEffect(() => {
//     fetchUsers(); // Fetch users on component mount
//   }, []);

//   if (error) {
//     return <p className="error">{error}</p>;
//   }

//   return (
//     <div className="user-list">
//       <h3>Users List</h3>
//       <ul>
//         {users.map(user => (
//           <li key={user.id}>
//             <p>Name: {user.name || 'N/A'}</p>
//             <p>Email: {user.email || 'N/A'}</p>
//             <p>Role: {user.role || 'N/A'}</p>
//             <p>Phone Number: {user.phoneNumber || 'N/A'}</p>
//             <button onClick={() => deleteUser(user.id)}>Delete User</button>

//             <a href="`/${user.phoneNumber}`">Contact User</a>

//           </li>
//         ))}
//       </ul>
//       <table border={2}>
//             <thead>
//                 <th>User Name</th>
//                 <th>Phone Number</th>
//                 <th>Email</th>
//                 <th>Role</th>
//                 <th>Delete User</th>
//             </thead>
//             <tbody>
//                 {users.map(user => (
//                     <tr key={user.id}>
//                         <td>{user.name || 'N/A'}</td>
//                         <td>{user.phoneNumber || 'N/A'}</td>
//                         <td>{user.email || 'N/A'}</td>
//                         <td>{user.role || 'N/A'}</td>
//                         <td> <button onClick={() => deleteUser(user.id)}>Delete User</button></td>
//                     </tr>
//                 ))}
//             </tbody>
//           </table>

//       <ToastContainer />
//     </div>
//   );
// };

// export default UserList;
