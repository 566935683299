import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import './SingleImage.css'; 
import images from './Image';
const SingleImage = () => {
  const { id } = useParams();
  const image = images[id];
  const navigate = useNavigate();

  if (!image) {
    return <p>Image not found!</p>;
  }

  return (
    <div className="single-image-container">
      <button className="go-back-button" onClick={() => navigate(-1)}>Back</button>
      <div className="single-image">

        <div className="content">
          <h2>{image.title}</h2>
          <img src={image.url} alt="" />
          <p>{image.description1}</p>
        </div>

        <h3>Quick Order <a href='https://wa.me/2348029299901'>HERE</a></h3>

      </div>
    </div>
  );
};

export default SingleImage;
