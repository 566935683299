import React from 'react';
import ChatComponent from './ChatComponent';


const UserChat = () => {
  const adminId = 'jBSIZLlZLnXOtyf9uh2mVnLoDWF3'; // Replace with actual admin ID
  //   const adminId = process.env.ADMIN_USER_ID

  return <ChatComponent receiverId={adminId} isAdmin={false} />;
};

export default UserChat;
