// src/components/GoogleMap.jsx
import React from 'react';
import "./GoogleMap.css";

const GoogleMap = () => {
  return (
    <div className='map'>
      <iframe
        title="Multibrand Digital Services Location Map" // Add title attribute for accessibility
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15855.537482520003!2d3.3702702!3d6.5362842!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8d8c600a2cb7%3A0xdaaab60f3c2c9173!2sMultibrand%20Digital%20Services!5e0!3m2!1sen!2sng!4v1727734551470!5m2!1sen!2sng"
        width="700"
        height="400"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default GoogleMap;
