import React from 'react'
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import "./Footer.css";
import { MdEmail } from 'react-icons/md';
import Contact from '../pages/Contact';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-container'>
        <div className='footer-one'>
        <Contact/>
        </div>

        <div className='footer-paystack-image'>
        <img src="/images/paystack.png" alt="" />
         <div className="footer-social">
            <a href="https://facebook.com/multibrandprint"><FaFacebook/></a>
            <a href="https://instagram.com/multibrandprints"><FaInstagram/></a>
            <a href="https://twitter.com/oguntoyintaiwo"><FaTwitter/></a>
            <a href="mailto:multibranddigital@gmail.com"><MdEmail/></a>
         </div>
        </div>

        <div className="footer-links">
             <Link to={"/company-profile"} className='link-to-page'>About Us</Link> 
             <Link to={"/tc"} className='link-to-page'>Terms and conditions</ Link> 
             <Link to={"/feedback"} className='link-to-page'>Send Feedback</Link>
             <Link to={"/privacy"} className='link-to-page'>Privacy & Policy</Link>
             <Link to={"/feedbacklist"} className='link-to-page'>customer Reviews</Link> 
         </div>
           <div className='footer-paragraph'>
            <b>TO ALL OUR CUSTOMERS</b><br />
            Thank you for choosing Multibrand Digital Services, where your ideas take shape and come to life.
           <div>

          </div>
        </div>
       </div>
       <hr />
         <p className='footer-under'><b>Multibrand Digital Services.</b> 2024 © All rights reserved. facilitated by. <Link to={"https://www.oguntoyinbo.com.ng"}> <strong style={{color:"skyBlue"}}> MultiBrand.</strong></Link></p>
       </footer>
  )
}

export default Footer