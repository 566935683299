import React, { useState } from 'react';
import { db, storage } from '../../firebase'; // Ensure Firebase is correctly initialized
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './AddProductForm.css';

const AddProductForm = ({ onProductAdded }) => {
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState(null); // Main product image
  const [detailImages, setDetailImages] = useState([]); // Array for detail images
  const [categoryName, setCategoryName] = useState('');
  const [quantity, setQuantity] = useState('');
  const [loading, setLoading] = useState(false); // New state to handle loading status

  // New states for the product features
  const [feature1, setFeature1] = useState('');
  const [feature2, setFeature2] = useState('');
  const [feature3, setFeature3] = useState('');

  const categories = [
    'Books',
    'Bags',
    'Letterhead',
    'Branding',
    'Clothes',
    'Fliers',
    'Company Branding',
    'Business Cards',
    'Caps',
    'Custom Mugs',
    'Calendar',
  ];

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Handle change for detail images
  const handleDetailImagesChange = (e) => {
    setDetailImages([...e.target.files]); // Convert FileList to array
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file || detailImages.length < 3) {
      alert('Please upload the main image and three detail images.');
      return;
    }

    setLoading(true); // Set loading to true when the process starts

    try {
      // Step 1: Upload main product image to Firebase Storage
      const storageRef = ref(storage, `images/${file.name}`);
      await uploadBytes(storageRef, file);
      const imageUrl = await getDownloadURL(storageRef);

      // Step 2: Upload the three detail images to Firebase Storage
      const detailImageUrls = [];
      for (let i = 0; i < detailImages.length; i++) {
        const detailImage = detailImages[i];
        const detailImageRef = ref(storage, `images/details/${detailImage.name}`);
        await uploadBytes(detailImageRef, detailImage);
        const detailImageUrl = await getDownloadURL(detailImageRef);
        detailImageUrls.push(detailImageUrl); // Add each URL to the array
      }

      // Step 3: Add product details to Firestore
      await addDoc(collection(db, 'products'), {
        name,
        price: parseFloat(price), // Ensure price is saved as a float
        description,
        imageUrl,
        detailImages: detailImageUrls, // Save array of detail images
        categoryName,
        quantity: parseInt(quantity, 10), // Ensure quantity is saved as an integer
        features: [feature1, feature2, feature3], // Save the features as an array
        timestamp: new Date(),
      });

      // Clear form after successful submission
      setName('');
      setPrice('');
      setDescription('');
      setFile(null);
      setDetailImages([]); // Clear detail images
      setCategoryName('');
      setQuantity('');
      setFeature1(''); // Clear features
      setFeature2('');
      setFeature3('');

      onProductAdded();
      alert('Product added successfully!');
    } catch (error) {
      console.error('Error adding product:', error);
      alert('Failed to add product. Please try again.');
    } finally {
      setLoading(false); // Reset loading status
    }
  };

  return (
    <form onSubmit={handleSubmit} className='add-product-form'>
      <h2>Add Product</h2>
      <div>
        <label>Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Price:</label>
        <input
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Description:</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Main Image:</label>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          required
        />
      </div>
      <div>
        <label>Detail Images (3):</label>
        <input
          type="file"
          accept="image/*"
          onChange={handleDetailImagesChange}
          multiple
          required
        />
      </div>
      <div>
        <label>Quantity:</label>
        <input
          type="number"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
          required
        />
      </div>
      <div className='select-category-button'>
        <label>Category:</label>
        <select
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          required
        >
          <option value="" disabled>
            Select a category
          </option>
          {categories.map((cat) => (
            <option key={cat} value={cat}>
              {cat}
            </option>
          ))}
        </select>
      </div>

      {/* New inputs for product features */}
      <div>
        <label>Feature 1:</label>
        <input
          type="text"
          value={feature1}
          onChange={(e) => setFeature1(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Feature 2:</label>
        <input
          type="text"
          value={feature2}
          onChange={(e) => setFeature2(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Feature 3:</label>
        <input
          type="text"
          value={feature3}
          onChange={(e) => setFeature3(e.target.value)}
          required
        />
      </div>

      <button type="submit" disabled={loading}>
        {loading ? 'Adding Product...' : 'Add Product'}
      </button>
    </form>
  );
};

export default AddProductForm;
