import React, { useContext, useState } from 'react';
import { FaSearch, FaTimes, FaMoon, FaUserTie } from 'react-icons/fa';
import { RxHamburgerMenu } from "react-icons/rx";
import { FiSun } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { DarkModeContext } from '../../DarkModeContext';
import { CartContext } from '../../CartContext';
import { AuthContext } from '../../AuthContext';  // Import AuthContext
import ProductArray from '../products/ProductArray';
import './Navbar.css';
import { debounce } from 'lodash';
import { IoLogoWhatsapp } from 'react-icons/io';
import { GiShoppingCart } from "react-icons/gi";

const Navbar = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [cartDropdownVisible, setCartDropdownVisible] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);
  const { cartItems } = useContext(CartContext);
  const { currentUser, logOut } = useContext(AuthContext); 
  
  const navigate = useNavigate();

  // Toggles
  const toggleDropdown = () => setDropdownVisible(prev => !prev);
  const toggleSearch = () => setSearchVisible(prev => !prev);
  const toggleCartDropdown = () => setCartDropdownVisible(prev => !prev);

  // Search logic
  const updateSearchResults = debounce((input) => {
    if (input.trim()) {
      const allProducts = Object.values(ProductArray).flat();
      const filteredResults = allProducts.filter(product =>
        product.name.toLowerCase().includes(input.toLowerCase())
      );
      setSearchResults(filteredResults);
    } else {
      setSearchResults([]);
    }
  }, 300);

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
    updateSearchResults(e.target.value);
  };

  const handleProductClick = (product) => {
    console.log('Product clicked:', product);
    setSearchInput('');
    setSearchResults([]);
    setSearchVisible(false);
  };

  // Logout function
  const handleLogout = () => {
    logOut();  // Log out the user
    navigate('/login');  // Redirect to login page
    setDropdownVisible(false);  // Close dropdown after logout
  };

  // Cart total calculation
  const totalCartItems = cartItems.reduce((total, item) => total + item.quantity, 0);
  const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

  // Function to close the dropdown when any link is clicked
  const handleLinkClick = () => {
    setDropdownVisible(false);  // Close the dropdown
  };

  return (
    <div className={`navbar ${isDarkMode ? 'dark-mode' : ''}`}>
      <header>
        <div className="logo">
          <h1>MULTIBRAND</h1>
        </div>
        <ul className={`ul-list ${dropdownVisible ? 'show' : ''}`}>
          {/* Conditionally display links based on auth status */}
          {currentUser ? (
            <Link to="/user-dashboard/user-chat" className='navbar-chat-with-admin' onClick={handleLinkClick}>Click to Chat with Us</Link>
          ) : (
            <a href="/register" className='navbar-chat-with-admin' onClick={handleLinkClick}>SignUp or Login to chat with Our Team</a>
            
          )}
          {currentUser ? (<Link to="/user-dashboard/profile" onClick={handleLinkClick}><li style={{fontSize:"25px", color:"green"}}><FaUserTie /></li></Link>):('')}
          <Link to="/adminproducts" onClick={handleLinkClick}><li>Products</li></Link>
          <Link to="/company-profile" onClick={handleLinkClick}><li>About Us</li></Link>
          <Link to="tel:+2348029299901" onClick={handleLinkClick}><li>Call For Designs</li></Link>
          <Link to="/" onClick={handleLinkClick}><li>Home</li></Link>

          {/* Show login or log out based on user status */}
          {currentUser ? (
            <button className="nav-auth" onClick={handleLogout} >Log Out</button>
          ) : (
            <a href="/login" className="nav-auth">Login</a>
          )}
        </ul>

        <ul className="ul-icon">
          <li><FaSearch onClick={toggleSearch} className="search-btn" /></li>
          
          <Link to="https://wa.me/2348029299901" className="whatsapp-icon" style={{color:"#5ee75e", fontSize:"25px"}}><IoLogoWhatsapp /></Link>

          <li className="cart-icon" onClick={toggleCartDropdown} style={{ position: 'relative', fontSize:"30px", fontWeight:"700" }}>
          <GiShoppingCart />
            {totalCartItems > 0 && <span className="cart-badge">{totalCartItems}</span>}
            {cartDropdownVisible && (
              <div className="cart-dropdown">
                <ul>
                  {cartItems.length > 0 ? (
                    cartItems.map((item) => (
                      <li key={item.id}>
                        <p>{item.name} x {item.quantity}</p>
                      </li>
                    ))
                  ) : (
                    <p className="cart-empty">Your cart is empty</p>
                  )}
                </ul>
                <h5 className='nav-cart-total'>Total Amount: ₦{totalPrice.toFixed(2)}</h5>
                <Link to="/user-dashboard/carts" className="go-to-cart">Go to Cart</Link>
              </div>
            )}
          </li>

          <li onClick={toggleDarkMode} className="dark-mode-toggle">
            {isDarkMode ? <FiSun /> : <FaMoon />}
          </li>
        </ul>

        {/* Mobile menu toggle */}
        <button className="dropdown-btn" onClick={toggleDropdown} aria-label="Toggle menu">
          {dropdownVisible ? <FaTimes /> : <RxHamburgerMenu />}
        </button>

        {/* Search input and results */}
        {searchVisible && (
          <div className="navbar-search">
            <div className="search-component">
              <input
                type="text"
                placeholder="Search for products here..."
                value={searchInput}
                onChange={handleSearchInput}
                aria-label="Search products"
              />
              <button className="search-button" aria-label="Search">
                <FaSearch />
              </button>
            </div>
            {searchResults.length > 0 && (
              <div className="search-results-dropdown">
                <ul>
                  {searchResults.map((product) => (
                    <li key={product.id} onClick={() => handleProductClick(product)}>
                      <Link to={`/search/${product.name.toLowerCase()}`}>
                        <img src={product.image} alt={product.name} />
                        <h5>{product.name}</h5>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </header>
    </div>
  );
};

export default Navbar;
