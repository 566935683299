// src/pages/Profile.jsx
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../AuthContext'; // Assuming you have an AuthContext
import { db } from '../../firebase'; // Adjust the path as needed
import { doc, getDoc, updateDoc } from 'firebase/firestore'; // Import updateDoc to update the document
import { useNavigate } from 'react-router-dom';
import './Profile.css'; // Import the CSS file
import { Vortex } from 'react-loader-spinner';
import Loader from '../Loader';

const Profile = () => {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false); 
  const [updatedUserData, setUpdatedUserData] = useState({}); 
  const [updateError, setUpdateError] = useState(null);
  const [updateSuccess, setUpdateSuccess] = useState(false); 
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
      return;
    }

    const fetchUserData = async () => {
      try {
        const userDoc = doc(db, 'users', currentUser.uid);
        const userSnapshot = await getDoc(userDoc);

        if (userSnapshot.exists()) {
          setUserData(userSnapshot.data());
          setUpdatedUserData(userSnapshot.data()); // Initialize the updated data with current data
        } else {
          setError('No user data found');
        }
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Failed to fetch user data');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [currentUser, navigate]);

  // Function to handle input changes for editable fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUserData({
      ...updatedUserData,
      [name]: value,
    });
  };

  // Function to save the updated user profile
  const handleSaveChanges = async () => {
    setLoading(true);
    setUpdateError(null);
    setUpdateSuccess(false);
    try {
      const userDoc = doc(db, 'users', currentUser.uid);
      await updateDoc(userDoc, updatedUserData);
      setUserData(updatedUserData); // Update the UI with the new data
      setIsEditing(false); // Exit editing mode
      setUpdateSuccess(true); // Show success message
    } catch (err) {
      console.error('Error updating profile:', err);
      setUpdateError('Failed to update profile. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="profile-container">
      <h2>Your Profile</h2>
      {loading && (
        <Loader/>
      )}
      {error && <p className="error">{error}</p>}
      {updateError && <p className="error">{updateError}</p>}
      {updateSuccess && <p className="success">Profile updated successfully!</p>}
      {userData && !loading && (
        <div className="profile-box">
          <p>
            <strong>Email: </strong> {userData.email}
          </p>
          <hr />

          {/* Full Name Field */}
          <p>
            <strong>Full Name: </strong>
            {isEditing ? (
              <input
                type="text"
                name="name"
                value={updatedUserData.name || ''}
                onChange={handleInputChange}
              />
            ) : (
              userData.name
            )}
          </p>
          <hr />

          {/* Address Fields */}
          <p>
            <strong>Street Address: </strong>
            {isEditing ? (
              <input
                type="text"
                name="streetAddress"
                value={updatedUserData.streetAddress || ''}
                onChange={handleInputChange}
              />
            ) : (
              userData.streetAddress
            )}
          </p>
          <hr />
          <p>
            <strong>City: </strong>
            {isEditing ? (
              <input
                type="text"
                name="city"
                value={updatedUserData.city || ''}
                onChange={handleInputChange}
              />
            ) : (
              userData.city
            )}
          </p>
          <hr />
          <p>
            <strong>State: </strong>
            {isEditing ? (
              <input
                type="text"
                name="state"
                value={updatedUserData.state || ''}
                onChange={handleInputChange}
              />
            ) : (
              userData.state
            )}
          </p>
          <hr />
          <p>
            <strong>Country: </strong>
            {isEditing ? (
              <input
                type="text"
                name="country"
                value={updatedUserData.country || ''}
                onChange={handleInputChange}
              />
            ) : (
              userData.country
            )}
          </p>
          <hr />

          {/* Phone Number Field */}
          <p>
            <strong>Phone Number: </strong>
            {isEditing ? (
              <input
                type="tel"
                name="phoneNumber"
                value={updatedUserData.phoneNumber || ''}
                onChange={handleInputChange}
              />
            ) : (
              userData.phoneNumber
            )}
          </p>
          <hr />

          {/* Edit and Save Buttons */}
          {!isEditing ? (
            <button onClick={() => setIsEditing(true)}>Edit Profile</button>
          ) : (
            <div>
              <button onClick={handleSaveChanges}>Save Changes</button>
              <button onClick={() => setIsEditing(false)}>Cancel</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Profile;
