import React, { useEffect, useState, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { CartContext } from '../../CartContext';
import './SingleProductPage.css';
import GoBackComponent from '../GoBackComponent';
import { useAuth } from '../../AuthContext';
import Loader from '../Loader';
import Faq from '../Faq';

const SingleProductPage = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState('');
  const { addToCart } = useContext(CartContext);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [customInfo, setCustomInfo] = useState(''); // New state for custom information

  useEffect(() => {
    const fetchProduct = async () => {
      const productDoc = doc(db, 'products', productId);
      const productSnapshot = await getDoc(productDoc);
      if (productSnapshot.exists()) {
        const fetchedProduct = { id: productSnapshot.id, ...productSnapshot.data() };
        setProduct(fetchedProduct);
        setSelectedImage(fetchedProduct.imageUrl);
        fetchRelatedProducts(fetchedProduct.categoryName);
      } else {
        console.error('Product not found');
      }
    };

    const fetchRelatedProducts = async (category) => {
      try {
        const productsRef = collection(db, 'products');
        const q = query(productsRef, where('categoryName', '==', category));
        const querySnapshot = await getDocs(q);
        const related = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setRelatedProducts(related.filter(item => item.id !== productId).slice(0, 6));
      } catch (error) {
        console.error('Error fetching related products:', error);
      }
    };

    fetchProduct();
  }, [productId]);

  if (!product) {
    return (
      <div>
        <Loader/>
      </div>
    );
  }

  const handleAddToCart = async () => {
    if (!currentUser) {
      navigate('/login');
    } else {
      try {
        const cartItem = {
          id: product.id,
          name: product.name,
          price: product.price,
          imageUrl: product.imageUrl,
          userId: currentUser.uid,
          customInfo, // Include custom information in the cart item
        };
        await addToCart(cartItem);
        alert('Added to cart successfully!');
        setCustomInfo(''); // Clear custom information after adding to cart
      } catch (error) {
        console.error('Error adding to cart:', error);
      }
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  return (
    <div className="single-product">
      <h2>{product.name}</h2>
      <div className='single-product-box'>
        <p className='quantity'>MIN: {product.quantity} Unit(s)</p>
        <img
          src={selectedImage}
          alt={product.name}
          className="main-image"
          onClick={() => setSelectedImage(product.imageUrl)}
        />
        <div className="image-thumbnails">
          <img
            src={product.imageUrl}
            alt="Main"
            className="thumbnail"
            onClick={() => handleImageClick(product.imageUrl)}
          />
          {product.detailImages?.map((imgUrl, index) => (
            <img
              key={index}
              src={imgUrl}
              alt={`Detail ${index + 1}`}
              className="thumbnail"
              onClick={() => handleImageClick(imgUrl)}
            />
          ))}
        </div>
        <div className='single-product-details'>
          <h3>Product Name: <strong>{product.name}</strong></h3>
          <p>Product Price: <strong>N {product.price} Per {product.quantity}</strong></p>
          <h4>Product Description:</h4>
          <p>{product.description}</p>
          <h4>Features:</h4>
          <ul className="features-list">
            {product.features?.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
         
          <p className='rating'>Rating: {product.rating} / 5</p>
        </div>
      </div>

      {/* Text Area for Custom Information */}
      <div className="custom-info-container">
        <label htmlFor="customInfo">Enter Custom Information:</label>
        <textarea
          id="customInfo"
          value={customInfo}
          onChange={(e) => setCustomInfo(e.target.value)}
          placeholder="Enter any specific details you want on this product..."
          rows="4"
        />
      </div>

      <div className="cart-button">
        <button onClick={handleAddToCart} className='add-to-cart-button'>Add to Cart</button>
        <Link to={currentUser ? "/user-dashboard/carts" : "/login"}>
          <button className='add-to-cart-button'>View Cart</button>
        </Link>
        <GoBackComponent />
      </div>

      <div className="faq-relate">
       
        <Faq/>
        {/* Related Products Section */}
        <div className="relate-products">
        <h3>Related Products</h3>
        <div className="relate-products-grid">
          {relatedProducts.map((relatedProduct) => (
            <Link to={`/product/${relatedProduct.id}`} key={relatedProduct.id}>
              <div className="relate-product-item">
                <img src={relatedProduct.imageUrl} alt={relatedProduct.name} />
                <p>{relatedProduct.name}</p>
                <p>N{relatedProduct.price} Per {relatedProduct.quantity}</p>
              </div>
            </Link>
          ))}
        </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProductPage;
