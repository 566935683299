import React, { useState } from 'react';
import { auth, db } from '../../firebase';
import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail, sendEmailVerification } from 'firebase/auth';
import { setDoc, doc } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';
import './Auth.css'; // Import the shared CSS for both login and register
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { nigeriaCities, nigeriaStates } from '../address/nigeriaCities';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [country, setCountry] = useState('Nigeria'); // Default country to Nigeria
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [streetAddress, setStreetAddress] = useState(''); // Street Address field
  const [customCountry, setCustomCountry] = useState(''); // For custom country input if "Others" is selected
  const [customState, setCustomState] = useState(''); // Custom state input for "Others"
  const [customCity, setCustomCity] = useState(''); // Custom city input for "Others"
  const [customStreetAddress, setCustomStreetAddress] = useState(''); // Custom street address for "Others"
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [isCustomAddress, setIsCustomAddress] = useState(false); // Toggle between Nigeria and custom country inputs
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const checkIfEmailExists = async (email) => {
    try {
      const methods = await fetchSignInMethodsForEmail(auth, email);
      return methods.length > 0; // Returns true if email is already registered
    } catch (error) {
      console.error("Error checking email: ", error);
      return false;
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError(''); // Clear previous errors

    const emailAlreadyRegistered = await checkIfEmailExists(email);
    if (emailAlreadyRegistered) {
      setError('Email already exists. Please use a different email or log in.');
      setEmailExists(true);
      return;
    }

    try {
      // Create the user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Send verification email
      await sendEmailVerification(user);

      // Store user data in Firestore with additional fields
      await setDoc(doc(db, 'users', user.uid), {
        name,
        address: isCustomAddress
          ? {
              country: customCountry,
              state: customState,
              city: customCity,
              streetAddress: customStreetAddress,
            }
          : {
              country,
              state,
              city,
              streetAddress,
            },
        phoneNumber,
        role: 'user',  // Set the default role as 'user'
        email: user.email,
      });

      // Show a success message
      alert('Registration successful! Please check your email to verify your account.');

      // Redirect to login page after successful registration
      navigate('/login');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    if (selectedCountry === 'Others') {
      setIsCustomAddress(true);
      setCountry('');
    } else {
      setIsCustomAddress(false);
      setCountry(selectedCountry);
    }
    setState('');  // Reset state and city when country changes
    setCity('');
    setStreetAddress('');  // Reset street address when country changes
  };

  const handleStateChange = (e) => {
    setState(e.target.value);
    setCity('');  // Reset city when state changes
  };

  return (
    <div className="auth-container">
      <form className="auth-form" onSubmit={handleRegister}>
        <h3>Fill the Form below to Register</h3>
        {error && <p className="error">{error}</p>}
        <label>Name:</label>
        <input 
          type="text" 
          value={name} 
          placeholder='Lastname  and Firstname'
          onChange={(e) => setName(e.target.value)} 
          required 
        />

        <label>Country:</label>
        <select 
          value={isCustomAddress ? 'Others' : country} 
          onChange={handleCountryChange} 
          required
        >
          <option value="Nigeria">Nigeria</option>
          <option value="Others">Others</option>
        </select>

        {isCustomAddress ? (
          <>
            <label>Country (Custom):</label>
            <input 
              type="text" 
              value={customCountry} 
              onChange={(e) => setCustomCountry(e.target.value)} 
              placeholder="Enter your country" 
              required 
            />
            <label>State (Custom):</label>
            <input 
              type="text" 
              value={customState} 
              onChange={(e) => setCustomState(e.target.value)} 
              placeholder="Enter your state" 
              required 
            />
            <label>City (Custom):</label>
            <input 
              type="text" 
              value={customCity} 
              onChange={(e) => setCustomCity(e.target.value)} 
              placeholder="Enter your city" 
              required 
            />
            <label>Street Address:</label>
            <input 
              type="text" 
              value={customStreetAddress} 
              onChange={(e) => setCustomStreetAddress(e.target.value)} 
              placeholder="Enter your street address" 
              required 
            />
          </>
        ) : (
          <>
            <label>State:</label>
            <select 
              value={state} 
              onChange={handleStateChange} 
              required
            >
              <option value="">Select State</option>
              {nigeriaStates.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </select>

            {state && (
              <>
                <label>City:</label>
                <select 
                  value={city} 
                  onChange={(e) => setCity(e.target.value)} 
                  required
                >
                  <option value="">Select City</option>
                  {nigeriaCities[state].map((city) => (
                    <option key={city} value={city}>
                      {city}
                    </option>
                  ))}
                </select>
              </>
            )}

            <label>Street Address:</label>
            <input 
              type="text" 
              value={streetAddress} 
              onChange={(e) => setStreetAddress(e.target.value)} 
              placeholder="Enter your street address" 
              required 
            />
          </>
        )}

        <label>Phone Number:</label>
        <input 
          type="tel" 
          value={phoneNumber} 
          placeholder='+234 800 0000 0000'
          onChange={(e) => setPhoneNumber(e.target.value)} 
          required 
        />
        
        <label>Email:</label>
        <input 
          type="email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          required
          placeholder='Email' 
          className={emailExists ? 'error-input' : ''}
        />
        
        <label>Password:</label>
        <div className="password-input-container">
          <input 
            type={showPassword ? "text" : "password"} 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
            placeholder='Password'
          />
          <b
            type="button" 
            className="toggle-password" 
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Change button label */}
          </b>
        </div>
        
        <button type="submit">Register</button>
        <Link to="/login"  className='have-account'>Already have an account? Click here to Login</Link>
      </form>
    </div>
  );
};

export default Register;
