import React, { useState, useEffect } from 'react';
import './ScrollAnimation.css';

const ScrollAnimation = () => {
  const fastSentences = [
    "Design is our passion every day",
    "Print your ideas with high quality",
    "Creativity drives innovation in branding",
    "Branding your business with precision",
    "Marketing strategies that lead to success"
  ];

  const delayedSentences = [
    "Quality is our top priority",
    "Professionalism in every project we deliver",
    "Service that meets your expectations always",
    "Innovation through creative print solutions",
    "Excellence in everything we craft and design"
  ];

  const [fastIndex, setFastIndex] = useState(0);
  const [delayedIndex, setDelayedIndex] = useState(0);

  // Change sentences every 3 seconds
  useEffect(() => {
    const fastInterval = setInterval(() => {
      setFastIndex((prevIndex) => (prevIndex + 1) % fastSentences.length);
    }, 3000);

    const delayedInterval = setInterval(() => {
      setDelayedIndex((prevIndex) => (prevIndex + 1) % delayedSentences.length);
    }, 3000);

    return () => {
      clearInterval(fastInterval);
      clearInterval(delayedInterval);
    };
  }, [fastSentences.length, delayedSentences.length]);

  return (
    <div className="two-boxes-container">
      <div className="scroll-box fast-box">
        <h2>{fastSentences[fastIndex]}</h2>
      </div>
      <div className="scroll-box delayed-box">
        <h2>{delayedSentences[delayedIndex]}</h2>
      </div>
    </div>
  );
};

export default ScrollAnimation;
