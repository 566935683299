import React, { useState, useEffect } from "react";
import "./ReviewSlider.css";

const reviews = [
  {
    id: 1,
    name: "Alice Johnson",
    review: "The business cards I ordered came out beautifully! The quality exceeded my expectations.",
    image: "https://images.pexels.com/photos/1181519/pexels-photo-1181519.jpeg?auto=compress&cs=tinysrgb&w=600",
    rating: 5,
    category: "Google Review",
  },
  {
    id: 2,
    name: "Bob Smith",
    review: "I love the custom T-shirts! The printing was sharp, and the colors were vibrant.",
    image: "https://images.pexels.com/photos/2182970/pexels-photo-2182970.jpeg?auto=compress&cs=tinysrgb&w=600",
    rating: 5,
    category: "Facebook",
  },
  {
    id: 3,
    name: "Charlie Davis",
    review: "Fantastic service and quick turnaround on my brochures. Highly recommend!",
    image: "https://images.pexels.com/photos/3184611/pexels-photo-3184611.jpeg?auto=compress&cs=tinysrgb&w=600",
    rating: 4,
    category: "Instagram",
  },
  {
    id: 4,
    name: "Diana Evans",
    review: "The team at Multibrand Digital Services is professional and attentive. My project was in good hands.",
    image: "https://images.pexels.com/photos/1181414/pexels-photo-1181414.jpeg?auto=compress&cs=tinysrgb&w=600",
    rating: 5,
    category: "Twitter",
  },
  // Add more reviews as needed...
];

const ReviewSlider = () => {
  const [currentReview, setCurrentReview] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentReview((prevReview) => (prevReview + 1) % reviews.length);
    }, 7000); // Change review every 7 seconds
    return () => clearInterval(interval);
  }, []);

  const renderStars = (rating) => {
    return Array.from({ length: 5 }, (_, i) => (
      <span key={i} className={i < rating ? "star filled" : "star"}>
        ★
      </span>
    ));
  };

  return (
    <div className="reviews-container">
      <div className="review-item">
        <img
          src={reviews[currentReview].image}
          alt={reviews[currentReview].name}
          className="review-image"
        />
        <div className="review-content">
          <div className="review-header">
            <div className="review-category">{reviews[currentReview].category}</div>
            <div className="review-stars">{renderStars(reviews[currentReview].rating)}</div>
           </div>
          <p>{reviews[currentReview].review}</p>
          <h4>{reviews[currentReview].name}</h4>
        </div>
      </div>
    </div>
  );
};

export default ReviewSlider;
