// src/CartContext.js
import React, { createContext, useState, useEffect } from 'react';
import { db } from './firebase';
import { collection, getDocs, doc, setDoc, updateDoc, getDoc, query, where, deleteDoc } from 'firebase/firestore';
import { useAuth } from './AuthContext';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const { currentUser } = useAuth();

  // Load cart items from Firestore for the logged-in user
  useEffect(() => {
    const loadCartItems = async () => {
      if (currentUser) {
        const cartCollection = collection(db, 'cartItems');
        const cartQuery = query(cartCollection, where('userId', '==', currentUser.uid));
        const cartSnapshot = await getDocs(cartQuery);
        const cartList = cartSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setCartItems(cartList);
      }
    };

    loadCartItems();
  }, [currentUser]);

  // Add item to cart
  const addToCart = async (item) => {
    if (!currentUser) {
      console.error('No user is logged in');
      return;
    }

    const cartRef = doc(db, 'cartItems', `${currentUser.uid}_${item.id}`);
    const docSnap = await getDoc(cartRef);

    if (docSnap.exists()) {
      // If item exists in the cart, update the quantity
      const currentQuantity = docSnap.data().quantity || 1;
      await updateDoc(cartRef, { quantity: currentQuantity + 1 });
      setCartItems((prevItems) =>
        prevItems.map((cartItem) =>
          cartItem.id === item.id ? { ...cartItem, quantity: currentQuantity + 1 } : cartItem
        )
      );
    } else {
      // Add new item to cart
      await setDoc(cartRef, { ...item, quantity: 1, userId: currentUser.uid });
      setCartItems((prevItems) => [...prevItems, { ...item, quantity: 1 }]);
    }
  };

  // Update item quantity in the cart
  const updateCartItemQuantity = async (id, quantity) => {
    const cartRef = doc(db, 'cartItems', `${currentUser.uid}_${id}`);
    try {
      await updateDoc(cartRef, { quantity });
      setCartItems((prevItems) =>
        prevItems.map((item) => (item.id === id ? { ...item, quantity } : item))
      );
    } catch (error) {
      console.error('Error updating item quantity:', error);
    }
  };

  // Increase item quantity
  const increaseItemQuantity = async (id) => {
    const cartItem = cartItems.find((item) => item.id === id);
    if (cartItem) {
      await updateCartItemQuantity(id, cartItem.quantity + 1);
    } else {
      console.error(`No item found with id: ${id}`);
    }
  };

  // Decrease item quantity
  const decreaseItemQuantity = async (id) => {
    const cartItem = cartItems.find((item) => item.id === id);
    if (cartItem && cartItem.quantity > 1) {
      await updateCartItemQuantity(id, cartItem.quantity - 1);
    } else if (!cartItem) {
      console.error(`No item found with id: ${id}`);
    } else {
      console.error(`Quantity for item id: ${id} is already at 1`);
    }
  };

  // Remove item from the cart
  const removeFromCart = async (id) => {
    const cartRef = doc(db, 'cartItems', `${currentUser.uid}_${id}`);
    try {
      await deleteDoc(cartRef);
      setCartItems((prevItems) => prevItems.filter((item) => item.id !== id));
    } catch (error) {
      console.error('Error removing item from cart:', error);
    }
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        updateCartItemQuantity,
        increaseItemQuantity,
        decreaseItemQuantity,
        removeFromCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
