
import { useAuth } from '../../AuthContext';
import { Outlet, useNavigate } from 'react-router-dom';

import './UserDashboard.css';

const UserDashboard = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  return (
    <div className="user-dashboard">
      <h4>Welcome to Your User Dashboard, {currentUser?.email}</h4>

      <div className="actions">
        <button onClick={() => navigate('/user-dashboard/profile')}>View Profile</button>
        <button onClick={() => navigate('/user-dashboard/carts')}>View Cart</button>
        <button onClick={() => navigate('/user-dashboard/orders')}>Order</button>
        <button onClick={() => navigate('/user-dashboard/checkout')}>Checkout</button>
        <button onClick={() => navigate('/user-dashboard/user-chat')}>Chat with Admin</button>
      </div>
      <hr />
      
      <Outlet />
      
      {/* <UserChatComponent/> */}
      
    </div>
  );
};

export default UserDashboard;
