import React, { useState } from 'react';
import { auth } from '../../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import './Auth.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Reset error state before trying to log in
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Check if the email is verified
      if (!user.emailVerified) {
        setError('Please verify your email address before logging in.');
        return;
      }
      
      // Get user document from Firestore
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      
      // Check user role
      const userRole = userDoc.data()?.role;

      if (userRole === 'admin') {
        // Navigate to admin dashboard if the role is admin
        navigate('/admin-dashboard');
      } else {
        // Navigate to user dashboard if the role is not admin
        navigate('/user-dashboard');
      }
    } catch (error) {
      // Handle errors
      if (error.code === 'auth/user-not-found') {
        setError('User not found. Please check your email and try again.');
      } else if (error.code === 'auth/wrong-password') {
        setError('Invalid password. Please try again.');
      } else {
        setError('Login failed. Please try again.');
      }
    }
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="auth-container">
      <form className="auth-form" onSubmit={handleLogin}>
        <h2>Login</h2>
        {error && <p className="error-message">{error}</p>}
        <label>Email:</label>
        <input 
          type="email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          required 
          placeholder='Email'
        />
        <label>Password:</label>
        <div className="password-input-container">
          <input 
            type={showPassword ? "text" : "password"} 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
            placeholder='Password'
          />
          <b
            type="button" 
            className="toggle-password" 
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />} {/* Change button label */}
          </b>
        </div>
        <button type="submit">Login</button>
        <div className="auth-links">
          
          <Link to="/reset-password" className='forgot-password'>Forgot Password?</Link>
        </div>
        <Link to="/register" className='dont-have-account'>Don't have an account? Register here</Link>
      </form>
     
    </div>
  );
};

export default Login;