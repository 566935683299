import React from 'react';
import { Helmet } from 'react-helmet';
import "./PrivacyPolicy.css"

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <Helmet>
        <title>Privacy Policy - Multibrand Digital Services</title>
        <meta 
          name="description" 
          content="Our Privacy Policy outlines how Multibrand Digital Services collects, uses, and protects your information. We prioritize your privacy and handle your data with the utmost care and transparency." 
        />
      </Helmet>
      <h1>Privacy Policy</h1>
      <p>At Multibrand Digital Services, we value your trust and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your information to enhance your experience with us.</p>
      
      <h2>1. Information Collection</h2>
      <p>We collect information you provide directly, such as when you create an account, place an order, or contact customer support. This may include your name, email address, phone number, and payment details.</p>
      <p>We also collect information automatically, like website usage data and device information, to improve our services and website experience.</p>
      
      <h2>2. Use of Information</h2>
      <p>We use your information to:</p>
      <ul>
        <li>Process and fulfill your orders</li>
        <li>Provide customer support</li>
        <li>Improve our products and services</li>
        <li>Send you updates, promotions, and relevant information with your consent</li>
      </ul>

      <h2>3. Data Security</h2>
      <p>Your data security is our priority. We implement industry-standard security measures to protect your information. Only authorized personnel have access to your data, and we use secure payment gateways for financial transactions.</p>

      <h2>4. Information Sharing</h2>
      <p>We do not sell, trade, or rent your personal information to third parties. We may share your information with trusted partners who assist us in operating our website and serving you, but only under strict confidentiality agreements.</p>
      
      <h2>5. Cookies</h2>
      <p>We use cookies to personalize and enhance your experience on our website. Cookies allow us to understand your preferences and improve site functionality. You can control your cookie preferences in your browser settings.</p>
      
      <h2>6. Your Rights</h2>
      <p>You have the right to access, update, or delete your personal information. You may also opt out of receiving marketing communications from us at any time.</p>

      <h2>7. Changes to Our Privacy Policy</h2>
      <p>We may update our Privacy Policy periodically to reflect changes in our practices or legal requirements. Any updates will be posted on this page, and we encourage you to review it regularly.</p>

      <h2>8. Contact Us</h2>
      <p>If you have any questions or concerns about our Privacy Policy, please contact us at <a href="mailto:info@multibrandprints.com.ng">info@multibrandprints.com.ng</a>.</p>

      <p>By using our website, you consent to our Privacy Policy.</p>
    </div>
  );
};

export default PrivacyPolicy;
