import React, { useState, useEffect, useCallback } from "react";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { app } from "../../firebase";

const OrderComponent = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const db = getFirestore(app);
  const auth = getAuth(app);

  const fetchAllOrders = useCallback(async () => {
    const ordersRef = collection(db, "orders");
    const snapshot = await getDocs(ordersRef);
    console.log("All Orders:");
    snapshot.docs.forEach((doc) => {
      console.log("Order ID:", doc.id, "=>", doc.data());
    });
  }, [db]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        await fetchAllOrders(); // Log all orders

        const user = auth.currentUser;
        console.log("Current User:", user); // Check if user is logged in

        if (user) {
          console.log("Current User ID:", user.uid);
          const ordersRef = collection(db, "orders");
          const q = query(ordersRef, where("userId", "==", user.uid));
          const querySnapshot = await getDocs(q);

          console.log("Number of orders fetched:", querySnapshot.size);

          const fetchedOrders = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          if (fetchedOrders.length > 0) {
            setOrders(fetchedOrders);
          } else {
            setError("No orders found for this user.");
          }
        } else {
          setError("User not authenticated");
        }
      } catch (err) {
        setError("Error fetching orders: " + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [auth, db, fetchAllOrders]);

  if (loading) {
    return <p>Loading orders...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div>
      <h2>Your Orders</h2>
      <ul>
        {orders.map((order) => (
          <li key={order.id}>
            <h3>Order ID: {order.id}</h3>
            <p>Product Name: {order.items?.map(item => item.name).join(', ') || 'N/A'}</p>
            <p>Quantity: {order.items?.map(item => item.quantity).join(', ') || 'N/A'}</p>
            <p>Status: {order.status || "Pending"}</p>
            <p>Total Price: ${order.items?.reduce((acc, item) => acc + item.price * item.quantity, 0) || 0}</p>
            <p>Ordered By: {order.customerDetails?.name}</p>
            <p>Delivery Address: {order.customerDetails?.address}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OrderComponent;
