import React, { useState, useEffect, useRef } from 'react';
import { db, storage } from '../../firebase'; // Assuming you have Firebase storage set up
import { collection, query, where, onSnapshot, addDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../../AuthContext';
import './ChatComponent.css';
import { FaFileUpload } from 'react-icons/fa';
import { IoMdSend } from 'react-icons/io';

const ChatComponent = ({ receiverId, isAdmin, deleteMessage }) => {
  const { currentUser } = useAuth(); // Get current user from AuthContext
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [uploading, setUploading] = useState(false); // For tracking upload status
 
  const messagesEndRef = useRef(null); // Reference for the last message element

  useEffect(() => {
    if (!currentUser || !receiverId) return;

    const fetchMessages = () => {
      const q = query(
        collection(db, 'chats'),
        where('senderId', 'in', [currentUser.uid, receiverId]),
        where('receiverId', 'in', [currentUser.uid, receiverId])
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const msgs = [];
        snapshot.forEach((doc) => {
          msgs.push({ ...doc.data(), id: doc.id });
        });

        // Sort messages by timestamp in ascending order
        msgs.sort((a, b) => {
          const timeA = a.timestamp?.toMillis() || 0;
          const timeB = b.timestamp?.toMillis() || 0;
          return timeA - timeB;
        });

        setMessages(msgs);
      });

      return () => unsubscribe();
    };

    fetchMessages();
  }, [currentUser, receiverId]);

  // Scroll to the bottom when messages update
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;

    try {
      await addDoc(collection(db, 'chats'), {
        message: newMessage,
        senderId: currentUser.uid,
        receiverId: receiverId,
        timestamp: serverTimestamp(),
      });
      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error.message);
    }
  };

  // Handle file upload
  const handleFileUpload = async (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;
    
    setUploading(true);

    const storageRef = ref(storage, `chat-files/${currentUser.uid}-${Date.now()}-${selectedFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, selectedFile);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Handle progress if needed
      },
      (error) => {
        console.error('Error uploading file:', error);
        setUploading(false);
      },
      async () => {
        // File upload completed
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        await addDoc(collection(db, 'chats'), {
          message: downloadURL, // Storing the file URL as a message
          senderId: currentUser.uid,
          receiverId: receiverId,
          timestamp: serverTimestamp(),
          type: 'file', // Mark it as a file message
        });
        setUploading(false);
      }
    );
  };

  return (
    <div className="chat-container">
      <div className="messages-container">
        {messages.map((msg) => (
          <div
            key={msg.id}
            className={`message ${msg.senderId === currentUser.uid ? 'sent' : 'received'}`}
          >
            {msg.type === 'file' ? (
              <a href={msg.message} target="_blank" rel="noopener noreferrer">
                View Document
              </a>
            ) : (
              <p>{msg.message}</p>
            )}
            {isAdmin && (
              <button onClick={() => deleteMessage(msg.id)} className="delete-button">
                Delete
              </button>
            )}
          </div>
        ))}
        {/* Dummy div to track the end of the messages */}
        <div ref={messagesEndRef} />
      </div>

      <div className="message-input">
       {/* File upload input */}
        <label htmlFor="fileUpload" className="upload-label">
          <FaFileUpload />
        </label>
         <input
          type="file"
          id="fileUpload"
          onChange={handleFileUpload}
          disabled={uploading}
         />
        {uploading && <p className="uploading-status">Uploading...</p>}

        <input
          type="text"
          placeholder="Type a message"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
       
        <button onClick={handleSendMessage} disabled={uploading}>
        <IoMdSend />
        </button>

      </div>
    </div>
  );
};

export default ChatComponent;
