import React, { useEffect, useState } from 'react';
import { GoArrowRight } from "react-icons/go";
import "./Hero.css";
import images from './products/Image.js';
import { FaSearch } from 'react-icons/fa';
import Swal from 'sweetalert2';
import ProductArray from './products/ProductArray.js';
import { Link } from 'react-router-dom';

const Hero = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // 3000ms = 3 seconds
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (searchInput.trim()) {
      const allProducts = Object.values(ProductArray).flat(); 
      const filteredResults = allProducts.filter((product) =>
        product.name.toLowerCase().includes(searchInput.toLowerCase())
      );
      setSearchResults(filteredResults);
    } else {
      setSearchResults([]);
    }
  }, [searchInput]);

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };

  const showSwal = () => {
    Swal.fire({
      title: 'Select a Category',
      html: `
        <div class="category-showSwal">
          <p><a href="/category/Books" class="swal-category-link">Books</a></p>
          <p><a href="/category/Tshirt_Branding" class="swal-category-link">T-Shirt Branding</a></p>
          <p><a href="/category/Custom_Mugs" class="swal-category-link">Mugs</a></p>
          <p><a href="/category/Branding" class="swal-category-link">Branding</a></p>
          <p><a href="/category/Bags" class="swal-category-link">Bags</a></p>
        </div>
      `,
      showCloseButton: true,
      showConfirmButton: false,
      customClass: {
        popup: 'swal-popup',
        title: 'swal-title'
      }
    });
  };

  return (
    <div className='hero'>
      <div className="hero-container">
        <div className="hero-box-one">
          <h1>WHAT WOULD YOU LIKE TO PRINT?</h1>
          
          <div className="search-box">
            <input
              type="text"
              placeholder="Search for products here..."
              value={searchInput}
              onChange={handleSearchInput}
              aria-label="Search products"
            />
            <button className="search-button" aria-label="Search">
              <FaSearch />
            </button>

            {searchResults.length > 0 ? (
            <div className="search-results">
              <div className="search-product-grid">
                {searchResults.map((product) => (
                  <div key={product.id} className="search-product-card">
                    <Link key={product.id} to={`/search/${product.name.toLowerCase()}`}>
                      <img src={product.image} alt={product.name} />
                      <h5>{product.name}</h5>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            searchInput && <p className='no-product-found'>No products found matching your search.</p>
          )}
          </div>


         <h3>Top-quality printing services for unmatched branding excellence.</h3>

          <button className="start-print-button" onClick={showSwal}>
            Start Printing <GoArrowRight />
          </button>
        </div>

        <div className="hero-box-two">
          <img src={images[imageIndex].url} alt={images[imageIndex].alt} />
        </div>
      </div>
    </div>
  );
};

export default Hero;
