import React, { useEffect, useState, useRef } from 'react';
import './Counter.css';

const Counter = () => {
  const CountUp = ({ endValue, duration }) => {
    const [count, setCount] = useState(0);
    const counterRef = useRef(null);
    const hasCounted = useRef(false);

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting && !hasCounted.current) {
            startCounting();
            hasCounted.current = true; // Ensure counting starts only once
          }
        },
        { threshold: 0.5 } // Adjust this value to detect when the section is in view
      );

      if (counterRef.current) {
        observer.observe(counterRef.current);
      }

      return () => {
        if (counterRef.current) {
          observer.unobserve(counterRef.current);
        }
      };
    }, []);

    const startCounting = () => {
      let incrementValue = Math.ceil((endValue - count) / 50); // Add 50 numbers in the specified duration
      let interval = (duration / 50); // Spread over the specified duration

      const counter = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount + incrementValue >= endValue) {
            clearInterval(counter);
            return endValue;
          }
          return prevCount + incrementValue;
        });
      }, interval);
    };

    return (
      <h3 ref={counterRef}>{count}+</h3>
    );
  };

  return (
    <section className="counter-section">
      <h2>Our Achievements</h2>
      <div className="counter-grid">
        <div className="counter-item">
          <CountUp endValue={10000} duration={1500} />
          <p>Over 10,000+ high-quality printed materials delivered to satisfied clients.</p>
        </div>
        <div className="counter-item">
          <CountUp endValue={1500} duration={1500} />
          <p>With more than 1,500+ unique designs created for businesses and individuals.</p>
        </div>
        <div className="counter-item">
          <CountUp endValue={750} duration={1500} />
          <p>Our team consists of 750+ skilled professionals dedicated to excellence in printing.</p>
        </div>
        <div className="counter-item">
          <CountUp endValue={2000} duration={1500} />
          <p>Over 2,000+ successful projects completed across various printing services.</p>
        </div>
      </div>
    </section>
  );
};

export default Counter;
