
import { useNavigate } from "react-router-dom"

const GoBackComponent = () =>{
    const navigate = useNavigate();
    const goBack =()=>{
        navigate(-1)
    }
    return(
        <div>
          <button onClick={goBack} 
          style={{
            padding:"10px 5px",
            borderRadius:"5px",
            color:'white',
            fontWeight: 700,
            background: 'black',
            border: "0.1px solid white"
          }}>
            Back
          </button>
        </div>
    )
}

export default GoBackComponent