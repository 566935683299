import React, { useState } from 'react';
import './TandC.css';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const TandC = () => {
  const [sections, setSections] = useState([
    { title: "Introduction", content: "Welcome to Multibrand Digital Services. By using our services, you agree to comply with our terms and policies.", open: false },
    { title: "Services Offered", content: "We offer a wide range of printing and branding services. Please read carefully to understand the conditions of our services.", open: false },
    { title: "User Responsibilities", content: "Users must ensure the accuracy of the details provided and adhere to our guidelines while using our platform.", open: false },
    { title: "Privacy Policy", content: "We are committed to protecting your privacy. Please review our privacy policy for more information on data usage.", open: false },
    { title: "Payments & Refunds", content: "All payments are processed securely. Refunds are provided under specific conditions, as outlined in this section.", open: false },
    { title: "Limitation of Liability", content: "Our liability is limited to the value of services rendered. We are not liable for any consequential damages.", open: false },
    { title: "Changes to Terms", content: "We may update these terms periodically. By continuing to use our services, you agree to any updated terms.", open: false },
  ]);

  const toggleSection = (index) => {
    setSections(sections.map((section, i) => 
      i === index ? { ...section, open: !section.open } : section
    ));
  };

  return (
    <div className="tandc-container">
      <h1 className="tandc-title">Terms and Conditions</h1>
      {sections.map((section, index) => (
        <div key={index} className={`tandc-section ${section.open ? 'open' : ''}`}>
          <div className="tandc-header" onClick={() => toggleSection(index)}>
            <h2>{section.title}</h2>
            <div className="tandc-toggle-icon">
              {section.open ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          </div>
          {section.open && <p className="tandc-content">{section.content}</p>}
        </div>
      ))}
    </div>
  );
};

export default TandC;
