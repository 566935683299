export const nigeriaCities = {
    "Abia": ["Aba", "Umuahia", "Ohafia", "Arochukwu", "Isiala Ngwa", "Nkporo", "Ngor Okpala", "Ikwuano", "Bende", "Umu Nneochi"],
    "Adamawa": ["Yola", "Mubi", "Numan", "Ganye", "Jada", "Girei", "Hong", "Shelleng", "Maiha", "Demsa"],
    "Akwa Ibom": ["Uyo", "Ikot Ekpene", "Eket", "Abak", "Oron", "Ikot Abasi", "Etinan", "Nsit Atai", "Ika", "Mkpat Enin"],
    "Anambra": ["Awka", "Onitsha", "Nnewi", "Otuocha", "Ekwulobia", "Ogidi", "Nanka", "Umuoji", "Amichi", "Ukpo"],
    "Bauchi": ["Bauchi", "Azare", "Misau", "Jama’are", "Katagum", "Ganjuwa", "Darazo", "Tafawa Balewa", "Bauchi", "Itas/Gadau"],
    "Bayelsa": ["Yenagoa", "Ogbia", "Brass", "Sagbama", "Nembe", "Southern Ijaw", "Kolokuma/Opokuma", "Ekeremor", "Peremabiri", "Gbarain"],
    "Benue": ["Makurdi", "Otukpo", "Gboko", "Vandeikya", "Konshisha", "Adikpo", "Oju", "Ushongo", "Kwande", "Guma"],
    "Borno": ["Maiduguri", "Biu", "Bama", "Damasak", "Monguno", "Gwoza", "Chibok", "Kukawa", "Dikwa", "Ngala"],
    "Cross River": ["Calabar", "Ikom", "Obudu", "Ogoja", "Etung", "Obubra", "Akpabuyo", "Yala", "Abi", "Bakassi"],
    "Delta": ["Asaba", "Warri", "Sapele", "Ughelli", "Sapele", "Koko", "Udu", "Agbor", "Oghara", "Bomadi"],
    "Ebonyi": ["Abakaliki", "Afikpo", "Onueke", "Izzi", "Ohaozara", "Ikwo", "Ezza", "Ohaukwu", "Ezza South", "Afikpo North"],
    "Edo": ["Benin City", "Auchi", "Ekpoma", "Igueben", "Uromi", "Sapele", "Edo North", "Oredo", "Esan Central", "Esan North-East"],
    "Ekiti": ["Ado-Ekiti", "Ikere-Ekiti", "Iworoko", "Ikole-Ekiti", "Oye-Ekiti", "Ise-Ekiti", "Emure-Ekiti", "Ilejemeje", "Moba", "Ekiti West"],
    "Enugu": ["Enugu", "Nsukka", "Oji River", "Udi", "Agbani", "Nkpunano", "Ossomari", "Enugu-Ezike", "Nkanu", "Ikem"],
    "Gombe": ["Gombe", "Kaltungo", "Dukku", "Billiri", "Yamaltu-Deba", "Akko", "Shongom", "Kwaya-Kusar", "Kwami", "Pali"],
    "Imo": ["Owerri", "Orlu", "Okigwe", "Mbano", "Mbaise", "Ngor-Okpala", "Owerri West", "Owerri North", "Owerri Municipal", "Isu"],
    "Jigawa": ["Dutse", "Hadejia", "Birnin Kudu", "Kazaure", "Guri", "Jahun", "Kafin Hausa", "Miga", "Gagarawa", "Taura"],
    "Kaduna": ["Kaduna", "Zaria", "Kafanchan", "Jema'a", "Soba", "Lere", "Igabi", "Giwa", "Chikun", "Kubau"],
    "Kano": ["Kano", "Fagge", "Nasarawa", "Gwale", "Dala", "Kumbotso", "Tarauni", "Ungogo", "Rimin Gado", "Danbatta"],
    "Katsina": ["Katsina", "Daura", "Funtua", "Kankara", "Malumfashi", "Zango", "Kurfi", "Batagarawa", "Rimi", "Kaita"],
    "Kebbi": ["Birnin Kebbi", "Argungu", "Yauri", "Zuru", "Jega", "Dandi", "Sakaba", "Birnin Yauri", "Arewa", "Gwandu"],
    "Kogi": ["Lokoja", "Okene", "Idah", "Kabba", "Okehi", "Egbe", "Adavi", "Ofu", "Igalamela", "Mopa"],
    "Kwara": ["Ilorin", "Offa", "Lafiagi", "Ekiti", "Omu-Aran", "Share", "Pategi", "Ilesha Baruba", "Jebba", "Kabba"],
    "Lagos": ["Ikeja", "Surulere", "Lekki", "Victoria Island", "Ikorodu", "Yaba", "Apapa", "Ikeja", "Mushin", "Somolu"],
    "Nasarawa": ["Lafia", "Keffi", "Akwanga", "Doma", "Nasarawa", "Karshi", "Wamba", "Kokona", "Toto", "Karu"],
    "Niger": ["Minna", "Bida", "Kontagora", "Suleja", "Lapai", "Lambata", "Wushishi", "Rijau", "Magama", "Gbara"],
    "Ogun": ["Abeokuta", "Ijebu Ode", "Sango Ota", "Ota", "Ifo", "Ijebu Ife", "Shagamu", "Ado-Odo/Ota", "Ewekoro", "Yewa"],
    "Ondo": ["Akure", "Ondo", "Owo", "Ikare", "Oka-Akoko", "Iju", "Oba", "Ilesa", "Akungba", "Irun"],
    "Osun": ["Osogbo", "Ile-Ife", "Ilesa", "Ikirun", "Ikire", "Ede", "Ife", "Ilesa", "Iwo", "Ejigbo"],
    "Oyo": ["Ibadan", "Oyo", "Ogbomosho", "Saki", "Iseyin", "Igboora", "Ogbomoso", "Ilesa", "Ibarapa", "Oyo"],
    "Plateau": ["Jos", "Pankshin", "Bokkos", "Shendam", "Langtang", "Riyom", "Barkin Ladi", "Mangu", "Wase", "Kanke"],
    "Rivers": ["Port Harcourt", "Bonny", "Opobo", "Ahoada", "Bori", "Okrika", "Ikwerre", "Obio-Akpor", "Degema", "Eleme"],
    "Sokoto": ["Sokoto", "Tambuwal", "Wurno", "Gwadabawa", "Shagari", "Rabah", "Yabo", "Binji", "Dange Shuni", "Kware"],
    "Taraba": ["Jalingo", "Wukari", "Gembu", "Zing", "Ardo-Kola", "Sardauna", "Takum", "Donga", "Ibi", "Kurmi"],
    "Yobe": ["Damaturu", "Potiskum", "Nguru", "Gashua", "Buni Yadi", "Geidam", "Bade", "Dapchi", "Yunusari", "Jajere"],
    "Zamfara": ["Gusau", "Kaura Namoda", "Tsafe", "Maru", "Zurmi", "Shinkafi", "Bukkuyum", "Anka", "Gummi", "Goronyo"],
    "Abuja (FCT)": ["Gwagwalada", "Kubwa", "Maitama", "Wuse", "Asokoro", "Jabi", "Garki", "Bwari", "Kuje", "Abaji"]
  };

  export const nigeriaStates = [
    "Abia", "Adamawa", "Akwa Ibom", "Anambra", "Bauchi", "Bayelsa", "Benue", "Borno", 
    "Cross River", "Delta", "Ebonyi", "Edo", "Ekiti", "Enugu", "Gombe", "Imo", "Jigawa", 
    "Kaduna", "Kano", "Katsina", "Kebbi", "Kogi", "Kwara", "Lagos", "Nasarawa", "Niger", 
    "Ogun", "Ondo", "Osun", "Oyo", "Plateau", "Rivers", "Sokoto", "Taraba", "Yobe", "Zamfara", 
    "Abuja (FCT)"
  ];
  