import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import './AdminProductList.css';
import { SlArrowRight } from "react-icons/sl";
import Loader from '../Loader';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [loading, setLoading] = useState(true); 

  const predefinedCategories = ['Books', 'Bags', 'Letterhead', 'Branding', 'Clothes', 'Fliers', 'Business Cards', 'Caps', 'Custom Mugs', 'Calendar'];

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const productCollection = collection(db, 'products');
        const productSnapshot = await getDocs(productCollection);
        const productsList = productSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setProducts(productsList); 
        setLoading(false);
      } catch (err) {
        console.error('Error fetching products:', err);
        setLoading(false);
      }
    };

    const fetchCategories = async () => {
      try {
        const categoryCollection = collection(db, 'categories');
        const categorySnapshot = await getDocs(categoryCollection);
        const fetchedCategories = categorySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const allCategories = [...new Set([...predefinedCategories, ...fetchedCategories.map(c => c.name)])];
        setCategories(allCategories.map(name => ({ id: name, name })));
      } catch (err) {
        console.error('Error fetching categories:', err);
      }
    };

    fetchProducts();
    fetchCategories();
  }, []);

  const handleCategoryChange = async (e) => {
    const categoryName = e.target.value;
    filterProductsByCategory(categoryName);
  };

  const handleCategoryClick = (categoryName) => {
    filterProductsByCategory(categoryName);
  };

  const filterProductsByCategory = async (categoryName) => {
    setSelectedCategory(categoryName);
    setLoading(true);

    try {
      const productCollection = collection(db, 'products');
      let productSnapshot;

      if (categoryName) {
        const q = query(productCollection, where('categoryName', '==', categoryName));
        productSnapshot = await getDocs(q);
      } else {
        productSnapshot = await getDocs(productCollection);
      }

      const productsList = productSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productsList);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching products:', err);
      setLoading(false);
    }
  };

  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const categoryChunks = chunkArray(categories, 3); // Split categories into chunks of 3

  if (loading) return <div> <Loader/></div>;

  return (
    <div className='product-list'>
      <h1>Browse All Products Collection</h1>

      <div className="prod-head">
        <button onClick={() => filterProductsByCategory('')}>View All</button>
        <hr />
        <span>Our Product</span>
        
        {categoryChunks.map((chunk, index) => (
          <ul key={index}>
            {chunk.map(category => (
              <li key={category.id} onClick={() => handleCategoryClick(category.name)}>
                {category.name}
              </li>
            ))}
          </ul>
        ))}
      </div>

      <div  className="prod-list-mobile">

        <select value={selectedCategory} onChange={handleCategoryChange} className='product-select'>
          <option value=''>All Categories</option>
          {categories.map((category) => (
            <option key={category.id} value={category.name}>{category.name}</option>
          ))}
        </select>
      </div>

      <div className='product-container'>
        {products.map((product) => (
          <div key={product.id} className='product-box box'>
            <Link to={`/product/${product.id}`}>
              <img src={product.imageUrl} alt={product.name} />
            </Link>
            <h3>{product.name}</h3>
            <p>As low as <b>₦{product.price}</b> per {product.quantity}</p>
            
            <div className="productlist-button">
              <Link to={`/product/${product.id}`}>
                <button className='view-product-button'>View Product</button>
              </Link>
              <button 
                className='explore-more-button' 
                onClick={() => handleCategoryClick(product.categoryName)}
              >
                Explore More..<SlArrowRight />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductList;
