import React, { useState } from 'react';
import './Faq.css';

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const questions = [
    { question: "When will I receive my order?", answer: "Your order will typically be delivered within 3-5 business days, depending on your location." },
    { question: "What is the minimum quantity of prints I can order?", answer: "The minimum order quantity is usually 50 units, but this may vary depending on the type of product." },
    { question: "How do I place an order on your website?", answer: "To place an order, browse our products, select the desired options, add them to your cart, and complete checkout." },
    { question: "How can I see a physical sample?", answer: "You can request a sample by contacting us. Samples are typically provided at a small cost." },
    { question: "If I request a design, do I get a sample before delivery?", answer: "Yes, we send a digital proof for your approval before starting the final production." },
    { question: "What is the difference between a magic and a simple mug?", answer: "A magic mug changes color when hot liquid is poured inside, while a simple mug does not." },
    { question: "What type of paper lamination do you offer?", answer: "We offer both matte and gloss lamination options, depending on your preference." },
    { question: "What is the difference between matte and gloss lamination?", answer: "Matte lamination gives a soft, non-reflective finish, while gloss lamination provides a shiny, reflective finish." },
    { question: "What paper thickness do you offer?", answer: "We offer a range of paper thickness options, from 80gsm to 350gsm." },
  ];

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h2>Frequently Asked Questions</h2>
      {questions.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleAnswer(index)}>
            {faq.question}
            <span className={`faq-icon ${activeIndex === index ? 'open' : ''}`}>+</span>
          </div>
          <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
            <p>{faq.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Faq;
